import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation as useReactRouterLocation } from 'react-router-dom';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Divider,
  Toolbar,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  CircularProgress,
  Box,
  Typography
} from '@mui/material';
import {
  ViewList as ViewListIcon,
  FileUpload as FileUploadIcon,
  Person as PersonIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Dashboard as DashboardIcon,
  Business as BusinessIcon,
  Category as CategoryIcon,
  AttachMoney as AttachMoneyIcon,
  HelpOutline as HelpOutlineIcon,
  LocationOn as LocationIcon
} from '@mui/icons-material';

import InvoiceUploadDialog from './InvoiceUploadDialog';

const ToolbarComponent = ({ isOpen, toggleDrawer, inventoryApi ,api }) => {
  const navigate = useNavigate();
  const routerLocation = useReactRouterLocation();

  // We’ll store the location dropdown data
  const [availableLocations, setAvailableLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null); // store the entire object
  const [loadingLocations, setLoadingLocations] = useState(true);

  // For invoice upload
  const [uploadOpen, setUploadOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // For user feedback (snackbar)
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  // Navigation items configuration
  const navigationItems = [
    { title: 'Dashboard', path: '/dashboard', icon: <DashboardIcon /> },
    { title: 'All Invoices', path: '/dashboard/invoices', icon: <ViewListIcon /> },
    { title: 'Categories', path: '/dashboard/categories', icon: <CategoryIcon /> },
    { title: 'User Profile', path: '/dashboard/profile', icon: <PersonIcon /> },
    { title: 'Settings', path: '/dashboard/settings', icon: <PersonIcon /> },
    { title: 'Products', path: '/dashboard/price-updater', icon: <AttachMoneyIcon /> },
    { title: 'Vendors', path: '/dashboard/vendors', icon: <BusinessIcon /> },
    { title: 'How To', path: '/dashboard/how-to', icon: <HelpOutlineIcon /> },
  ];

  // Parse location name to only show the part after the first dash
  const getDisplayLocationName = (locName = '') => {
    const dashIndex = locName.indexOf('-');
    if (dashIndex !== -1) {
      return locName.substring(dashIndex + 1).trim();
    }
    return locName;
  };

  // On mount, fetch locations & restore last selected from localStorage
  useEffect(() => {
    let isMounted = true;
    setLoadingLocations(true);

    inventoryApi.getAllLocations()
      .then((response) => {
        if (!isMounted) return;
        const locations = response.data || [];
        setAvailableLocations(locations);
        setLoadingLocations(false);

        // Check localStorage for a saved location object
        const savedLocationJson = localStorage.getItem('preferredLocation');
        if (savedLocationJson) {
          try {
            const savedObj = JSON.parse(savedLocationJson);
            // If that location is still valid, set it
            const found = locations.find((loc) => loc.id === savedObj.id);
            if (found) {
              setSelectedLocation(found);
            } else if (locations.length > 0) {
              // fallback to first if the old one doesn't exist
              setSelectedLocation(locations[0]);
            }
          } catch (parseErr) {
            console.error('Error parsing saved location:', parseErr);
          }
        } else if (locations.length > 0) {
          // default to the first if nothing in localStorage
          setSelectedLocation(locations[0]);
        }
      })
      .catch((err) => {
        if (!isMounted) return;
        setError('Could not load locations');
        setLoadingLocations(false);
      });

    return () => {
      isMounted = false;
    };
  }, [inventoryApi]);

  // Whenever selectedLocation changes, persist it in localStorage
  useEffect(() => {
    if (selectedLocation) {
      localStorage.setItem('preferredLocation', JSON.stringify(selectedLocation));
    }
  }, [selectedLocation]);

  // Handle user changing location
  const handleLocationChange = (event) => {
    const val = event.target.value;
    const locObj = availableLocations.find((l) => String(l.id) === val);
    if (locObj) {
      setSelectedLocation(locObj);
      setSuccess(`Location changed to: ${getDisplayLocationName(locObj.name)}`);
      // Optionally: api.updateUserProfile({ locationId: locObj.id })
    }
  };

  // Invoice upload dialog
  const handleUploadOpen = () => setUploadOpen(true);
  const handleUploadClose = () => setUploadOpen(false);

  // Handle file drop for invoice upload
  const onDrop = async (acceptedFile) => {
    setIsLoading(true);
    setError(null);
    setSuccess(null);

    try {
      await api.uploadInvoice(acceptedFile[0]);
      setSuccess('Invoice uploaded successfully');
      setUploadOpen(false);
      navigate('/dashboard/invoices');
    } catch (uploadErr) {
      setError('Failed to upload invoice');
      console.error('Upload error:', uploadErr);
    } finally {
      setIsLoading(false);
    }
  };

  // Renders a list item that holds the location dropdown
  const renderLocationItem = () => {
    // If the drawer is collapsed, we only show an icon w/ tooltip
    if (!isOpen) {
      return (
        <Tooltip title="Location" placement="right" arrow>
          <ListItem sx={{ pl: 1 }}>
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: 'auto',
                justifyContent: 'center',
              }}
            >
              <LocationIcon />
            </ListItemIcon>
          </ListItem>
        </Tooltip>
      );
    }

    // If the drawer is expanded, show the dropdown
    return (
      <ListItem sx={{ pl: 2 }}>
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: 1,
            justifyContent: 'center',
          }}
        >
          <LocationIcon />
        </ListItemIcon>
        {loadingLocations ? (
          <CircularProgress size={24} />
        ) : (
          <FormControl fullWidth size="small">
            <InputLabel id="select-location-label">Location</InputLabel>
            <Select
              labelId="select-location-label"
              value={selectedLocation ? String(selectedLocation.id) : ''}
              label="Location"
              onChange={handleLocationChange}
            >
              {availableLocations.map((loc) => (
                <MenuItem key={loc.id} value={String(loc.id)}>
                  {getDisplayLocationName(loc.name)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </ListItem>
    );
  };

  return (
    <>
      <Drawer
        variant="permanent"
        open={isOpen}
        sx={{
          '& .MuiDrawer-paper': {
            position: 'relative',
            background: 'linear-gradient(180deg, #fafafa 0%, #ffffff 100%)',
            borderRight: '1px solid #e0e0e0',
            width: isOpen ? 240 : 60,
            transition: 'width 0.3s ease',
            overflowX: 'hidden',
          },
        }}
      >
        {/* Toolbar with drawer toggle */}
        <Toolbar>
          <IconButton onClick={toggleDrawer} size="large">
            {isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </Toolbar>
        <Divider />

        <List>
          {/* Location item at the top of the list */}
          {renderLocationItem()}

          {/* Navigation items */}
          {navigationItems.map((item) => {
            const selected = routerLocation.pathname === item.path;
            return (
              <Tooltip
                key={item.path}
                title={!isOpen ? item.title : ''}
                placement="right"
                arrow
              >
                <ListItem
                  button
                  onClick={() => navigate(item.path)}
                  selected={selected}
                  sx={{
                    transition: 'background-color 0.2s, padding-left 0.2s',
                    ...(selected
                      ? {
                          backgroundColor: 'rgba(0,0,0,0.08)',
                          '&:hover': {
                            backgroundColor: 'rgba(0,0,0,0.1)',
                          },
                        }
                      : {
                          '&:hover': {
                            backgroundColor: 'rgba(0,0,0,0.04)',
                          },
                        }),
                    pl: isOpen ? 2 : 1,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: isOpen ? 1 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  {isOpen && <ListItemText primary={item.title} />}
                </ListItem>
              </Tooltip>
            );
          })}

          {/* Upload Invoice Button */}
          <Tooltip title={!isOpen ? 'Upload Invoice' : ''} placement="right" arrow>
            <ListItem
              button
              onClick={handleUploadOpen}
              sx={{
                transition: 'background-color 0.2s, padding-left 0.2s',
                '&:hover': {
                  backgroundColor: 'rgba(0,0,0,0.04)',
                },
                pl: isOpen ? 2 : 1,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: isOpen ? 1 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <FileUploadIcon />
              </ListItemIcon>
              {isOpen && <ListItemText primary="Upload Invoice" />}
            </ListItem>
          </Tooltip>
        </List>
      </Drawer>

      {/* Dialog for uploading invoices */}
      <InvoiceUploadDialog
        open={uploadOpen}
        onClose={handleUploadClose}
        onDrop={onDrop}
        isLoading={isLoading}
        error={error}
        success={success}
      />

      {/* Snackbars for success/error */}
      <Snackbar
        open={!!error || !!success}
        onClose={() => {
          setError(null);
          setSuccess(null);
        }}
        autoHideDuration={6000}
      >
        <Alert severity={error ? 'error' : 'success'} sx={{ width: '100%' }}>
          {error || success}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ToolbarComponent;
