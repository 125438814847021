import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import { ThemeProvider } from '@mui/material/styles';
import LoadingOverlay from './LoadingOverlay';
import theme from './theme';
import PublicLayout from './Layouts/PublicLayout';
import ProtectedLayout from './Layouts/ProtectedLayout';
import HomePage from './components/HomePage/HomePage';
import Login from './components/Login/Login';
import SiteUpdate from './components/site-updates/SiteUpdates';
import ProduceDisplay from './components/ProduceDisplay/produceDisplay';
import UserKYC from './components/Login/kyc/UserKYC';
import CostCalculator from './components/CostCalculatorFolder/CostCalculator';
import InvoiceDashboard from './components/Invoices/invoiceMainPage';
import Dashboard from './components/Invoices/Dashboard';
import Settings from './components/Invoices/Views/settings';
import AllInvoicesPage from './components/Invoices/InvoiceSelector/AllInvoicesPage';
import InvoiceDetail from './components/Invoices/InvoiceDetail';
import CategoryView from './components/Invoices/Views/categoryView';
import SalesTool from './components/Invoices/salestool/SaleTool';
import Vendors from './components/Invoices/Vendors';
import Actions from './components/Invoices/actions';
import ProductManagement from './components/Invoices/priceUpdater/instantpriceupdater';
import HowTo from './components/howTo'
import UserProfile from './components/Invoices/userProfile/UserProfile'
import Inventory from './components/Invoices/inventory';
import { CustomerExistenceProvider } from './context/CustomerExistenceContext';
import { fetchUserToken, setUser } from './redux/authSlice';
import { getAuth, onAuthStateChanged, setPersistence, browserLocalPersistence } from 'firebase/auth';
import Product from './components/Invoices/priceUpdater/ProductDetails'
import './firebase';

Modal.setAppElement('#root');

function App() {
  const dispatch = useDispatch();
  const auth = getAuth();
  const { status, user } = useSelector((state) => state.auth);

  useEffect(() => {
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          dispatch(setUser(user));
          if (user) {
            dispatch(fetchUserToken());
          }
        });
  
        return () => unsubscribe();
      })
      .catch((error) => {
        console.error('Failed to set persistence:', error);
      });
  }, [auth, dispatch]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(fetchUserToken());
    }, 60 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [dispatch]);



  if (status === 'loading') {
    return <LoadingOverlay />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CustomerExistenceProvider>
        <Router>
          <Routes>
            {/* Public Routes */}
            <Route element={<PublicLayout />}>
              <Route path="/login" element={<Login />} />
              <Route path="/siteupdates" element={<SiteUpdate />} />
              <Route path="/produce" element={<ProduceDisplay />} />
            </Route>

            {/* Conditional Root Route */}
            <Route 
              path="/" 
              element={user ? <Navigate to="/dashboard" replace /> : <HomePage />} 
            />

            {/* Protected Routes */}
            <Route element={<ProtectedLayout />}>
              <Route path="/kyc" element={<UserKYC />} />
              <Route path="/cost-calculator" element={<CostCalculator />} />
              
              {/* Invoice Dashboard and its nested routes */}
              <Route path="/dashboard" element={<InvoiceDashboard user={user} />}>
                <Route index element={<Dashboard user={user} />} />
                <Route path="settings" element={<Settings />} />
                <Route path="invoices" element={<AllInvoicesPage />} />
                <Route path="invoices/:id" element={<InvoiceDetail />} />
                <Route path="categories" element={<CategoryView />} />
                <Route path="sales" element={<SalesTool />} />
                <Route path="vendors" element={<Vendors />} />
                <Route path="actions" element={<Actions />} />
                <Route path="price-updater" element={<ProductManagement />} />
                <Route path="profile" element={<UserProfile user={user}/>} />
                <Route path="product/:id" element={<Product />} />
                <Route path="how-to" element={<HowTo />} />
                <Route path="inventory" element={<Inventory /> } />
              </Route>
            </Route>

            {/* Catch all route */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
      </CustomerExistenceProvider>
    </ThemeProvider>
  );
}

export default App;