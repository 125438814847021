import React from 'react';
import { Box } from '@mui/material';

const LoadingOverlay = ({ isLoading = true }) => {
  return (
    <Box
      sx={{
        // Full screen overlay
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        // Center contents
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // Light white overlay
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        // Stay above other elements
        zIndex: 9999,

        // Fade-out style
        opacity: isLoading ? 1 : 0,
        transition: 'opacity 0.6s ease',
        // Prevent clicks when visible, allow clicks when hidden
        pointerEvents: isLoading ? 'all' : 'none',
      }}
    >
      <Box
        sx={{
          width: 300,
          height: 300,
          transformOrigin: 'center center',
          // Rotate the entire SVG
          // Keyframes for spinning the entire SVG
          '@keyframes spin': {
            '0%': { transform: 'rotate(0deg)' },
            '100%': { transform: 'rotate(360deg)' },
          },

          // Now target the group with id="bouncingGroup" inside our SVG
          'svg #bouncingGroup': {
            // Apply our bounce animation
            animation: 'bounceUpDown 1s ease-in-out infinite',
          },

          // Define bounce keyframes
          '@keyframes bounceUpDown': {
            '0%, 100%': {
              transform: 'translateY(0px)',
            },
            '50%': {
              transform: 'translateY(-20px)',
            },
          },
        }}
      >
        {/* === Inline your SVG === */}
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 500 500"
          style={{ width: '100%', height: '100%' }}
        >
          <style type="text/css">
            {`
              .st0{fill:#95C23A;}
              .st1{fill:#90C43D;}
              .st2{fill:#E32722;}
              .st3{fill:#EA7B20;}
            `}
          </style>

          <circle className="st0" cx="309.85" cy="235.25" r="1.47" />
          <g id="bouncingGroup">
            <path
              className="st0"
              d="M255.58,237.16c-27.36-0.01-49.54-22.18-49.54-49.54c27.35-0.02,49.53,22.14,49.54,49.48
                C255.58,237.12,255.58,237.14,255.58,237.16z"
            />
            <circle className="st0" cx="187.73" cy="221.54" r="6.52" />
            <circle className="st0" cx="201.75" cy="220.23" r="4.28" />
            <circle className="st0" cx="317.38" cy="239.48" r="2.85" />
            <path
              className="st1"
              d="M309.8,261.41c-0.94,6.54-9.73,9.34-17.36,8.86c-0.24-1.87-0.38-3.81-0.47-5.72c0.08-1.33,0.35-2.63,0.81-3.88
                c0.83-2.28,2.35-4.24,4.35-5.6c-1.09,0.51-2.09,1.19-2.96,2.03c-0.43,0.42-0.84,0.86-1.21,1.34c-0.37,0.48-0.71,0.97-1.01,1.49
                c0.08-3.53,0.65-7.02,1.7-10.39c0.72-2.31,1.92-4.44,3.52-6.26c-3.43,3.24-5.36,8.1-6.37,13.24c-0.25-0.58-0.54-1.14-0.83-1.7
                c-1.19-2.07-2.68-3.95-4.43-5.58l-0.16-0.15c0.17,0.23,0.35,0.49,0.54,0.72c0.18,0.23,0.37,0.48,0.55,0.73
                c2.28,3.1,3.55,6.83,3.65,10.67c-0.09,0.87-0.15,1.73-0.21,2.58c-0.2-0.26-0.42-0.5-0.64-0.75l-0.73-0.69
                c-0.31-0.25-0.65-0.46-1.01-0.64c-0.34-0.19-0.71-0.32-1.09-0.38c0.67,0.32,1.24,0.8,1.67,1.41c0.88,1.19,1.45,2.58,1.66,4.05
                c0,1.11,0,2.29,0,3.36c0,0.16,0,0.33,0,0.5c-2.6,1.01-8.51,2.76-10.9-0.26c-3.04-3.86,0.67-8.66,0.84-8.87
                c-0.22,0.07-5.44,1.73-6.08-5.72c-0.64-7.45,6.59-6.51,6.59-6.51c-3.53-12.08,8.72-9.72,8.72-9.72s-0.55-8.03,6.91-7.61
                c7.45,0.43,3.82,10.01,3.82,10.01c2.23-0.55,4.55,0.5,5.62,2.53c1.83,3.52-1.54,6.91-1.54,6.91
                C306.87,251.41,310.95,253.27,309.8,261.41z"
            />
            <path
              className="st2"
              d="M276.5,259.94V260c-0.66,8.26-4.65,16.94-18.8,17.65c-0.22-3.79-3.47-6.68-7.25-6.46
                c-2.6,0.15-4.89,1.76-5.91,4.15c-6.51-3.32-9.62-9.66-10.42-15.4c-0.28-1.97-0.28-3.97,0-5.93
                C237.73,230.75,278.51,233.69,276.5,259.94z"
            />
            <path
              className="st3"
              d="M224.25,242.65c1.31,1.17,2.54,2.44,3.69,3.78l-5.49,7.29c-0.35,0.44-0.27,1.08,0.17,1.42
                c0.01,0.01,0.02,0.02,0.03,0.03c0.44,0.34,1.08,0.26,1.42-0.18c0.01-0.01,0.01-0.02,0.02-0.02l5.21-6.93
                c1.01,1.25,2.03,2.61,3.1,4.05l-4.1,5.46c-0.34,0.44-0.26,1.08,0.18,1.42c0.01,0.01,0.02,0.01,0.02,0.02l0.13,0.09
                c0.44,0.34,1.08,0.26,1.42-0.18c0.01-0.01,0.01-0.02,0.02-0.02l3.68-4.88c1.14,1.67,2.25,3.39,3.26,5.11l-2.03,2.75
                c-0.42,0.56-0.31,1.35,0.25,1.77c0.56,0.42,1.35,0.31,1.77-0.25l1.4-1.86c3.04,5.58,4.66,10.74,2.93,13.02
                c-3.71,4.92-27.15-1.67-37.03-9.11C188.91,253.88,209.43,229.83,224.25,242.65z"
            />
            <path
              className="st1"
              d="M201.04,234.34c0,0,4.55,3.9,0.19,9.37l-0.24,0.29l0,0c-0.06-0.04-0.11-0.09-0.16-0.14
                c-1.19-1.12-1.91-2.65-2.03-4.27C198.82,237.61,199.62,235.72,201.04,234.34z"
            />
            <path
              className="st1"
              d="M200.99,244.01L200.99,244.01c0,0,0,0,0,0.05c-4.05,6.08-9.24,2.68-9.24,2.68c0.93-1.79,2.56-3.11,4.51-3.66
                c1.57-0.32,3.21-0.04,4.59,0.78L200.99,244.01z"
            />
            <path
              className="st1"
              d="M198.77,239.62c1.31,0.98,2.19,2.43,2.46,4.05l-0.24,0.29l0,0c0,0,0,0,0,0.05c-1.64,0.17-3.3-0.18-4.73-1.01
                c-1.58-1.03-2.64-2.7-2.91-4.57C195.24,238.12,197.18,238.55,198.77,239.62z"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M336.02,257.65h-1.91c0-1.49,0-2.95-0.09-4.42c-1.75-46.19-31-84.33-70.9-91.58l0.34-1.87
                c40.77,7.4,70.69,46.33,72.46,93.42C336.02,254.66,336.02,256.15,336.02,257.65z"
            />
            <path
              className="st2"
              d="M165.82,257.65h-1.9c0-49.08,30.5-90.24,72.53-97.88l0.35,1.88C195.67,169.12,165.82,209.46,165.82,257.65z"
            />
            <path
              className="st2"
              d="M260.19,164.63h-20.42c-2.27,0.11-4.21-1.64-4.32-3.91c-0.11-2.27,1.64-4.21,3.91-4.32
                c0.14-0.01,0.27-0.01,0.41,0h20.42c2.27-0.11,4.21,1.64,4.32,3.91c0.11,2.27-1.64,4.21-3.91,4.32
                C260.46,164.64,260.32,164.64,260.19,164.63z M239.77,158.3c-1.22-0.08-2.28,0.84-2.36,2.07c-0.08,1.22,0.84,2.28,2.07,2.36
                c0.1,0.01,0.2,0.01,0.3,0h20.42c1.22-0.08,2.15-1.14,2.07-2.36c-0.07-1.11-0.96-1.99-2.07-2.07H239.77z"
            />
            <path
              className="st2"
              d="M339.1,259.82c-1.2,2.21-3.96,3.02-6.17,1.82c-0.85-0.46-1.53-1.18-1.94-2.05H169.02
                c-1.07,2.27-3.79,3.24-6.06,2.17c-0.87-0.41-1.59-1.09-2.05-1.94c-2.8,0.88-4.4,3.81-3.63,6.64l18.74,68.69
                c1.38,4.99,5.92,8.45,11.1,8.45h125.7c5.18,0,9.71-3.46,11.09-8.45l18.81-68.69C343.5,263.64,341.9,260.7,339.1,259.82z"
            />
          </g>
        </svg>
      </Box>
    </Box>
  );
};

export default LoadingOverlay;
