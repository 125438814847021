import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Paper,
  Tabs,
  Tab,
  CircularProgress,
  Snackbar,
  Alert,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Skeleton,
  Fade
} from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import GeneralSettings from './GeneralSettings';
import Integrations from './Integrations';
import TeamManagement from './TeamManagement';
import CompanyInfo from './CompanyInfo';

import createApi from '../inventoryApi';

const FadeTabPanel = ({ children, value, index, ...other }) => {
  return (
    <Fade in={value === index} timeout={500} mountOnEnter unmountOnExit>
      <div
        role="tabpanel"
        hidden={value !== index}
        {...other}
        style={{ transition: 'opacity 0.5s ease-out' }}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    </Fade>
  );
};

const Settings = () => {
  const { currentUser } = useOutletContext();
  
  // Instantiate your API (with the user token)
  const api = useMemo(() => {
    const token = currentUser?.stsTokenManager?.accessToken || '';
    return createApi(token);
  }, [currentUser?.stsTokenManager?.accessToken]);

  // Remember the last active tab from localStorage
  const [activeTab, setActiveTab] = useState(() => {
    const savedTab = localStorage.getItem('settingsActiveTab');
    return savedTab ? parseInt(savedTab, 10) : 0;
  });

  // Basic state for loading, error, success
  const [loading, setLoading] = useState(true);
  const [locationLoading, setLocationLoading] = useState(true); // Separate location loading
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  // For location preference
  const [availableLocations, setAvailableLocations] = useState([]);
  const [preferredLocation, setPreferredLocation] = useState(() => {
    return localStorage.getItem('preferredLocationId') || '';
  });

  // On mount, check if we have a currentUser
  useEffect(() => {
    if (!currentUser) {
      setError('No authenticated user found');
      setLoading(false);
      return;
    }
    setLoading(false);
  }, [currentUser]);

  // Actually fetch locations using your API
  useEffect(() => {
    setLocationLoading(true);
    api.getAllLocations()
      .then((response) => {
        setAvailableLocations(response.data);
        setLocationLoading(false);
      })
      .catch((err) => {
        console.error('Error fetching locations:', err);
        setError('Could not load locations');
        setLocationLoading(false);
      });
  }, [api]);

  // Persist the activeTab in localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('settingsActiveTab', activeTab);
  }, [activeTab]);

  // Handler for location changes
  const handleLocationChange = (newLoc) => {
    setPreferredLocation(newLoc);
    localStorage.setItem('preferredLocationId', newLoc);
    setSuccess(`Default location updated to Location ID = ${newLoc}`);
    // If storing in DB: e.g. api.updateUserProfile({ preferred_location_id: newLoc })...
  };

  // If overall loading (auth or something else)
  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Paper elevation={3}>
        {/* Tabs */}
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={activeTab}
            onChange={(e, newValue) => setActiveTab(newValue)}
          >
            <Tab label="General Settings" />
            <Tab label="Integrations" />
            <Tab label="Team Management" />
            <Tab label="Company Info" />
          </Tabs>
        </Box>

        {/* Snackbar for error/success */}
        <Snackbar
          open={!!error || !!success}
          autoHideDuration={6000}
          onClose={() => {
            setError(null);
            setSuccess(null);
          }}
        >
          <Alert severity={error ? 'error' : 'success'}>
            {error || success}
          </Alert>
        </Snackbar>

        {/* Tab Panels with Fade animations */}
        <FadeTabPanel value={activeTab} index={0}>
          <GeneralSettings onError={setError} onSuccess={setSuccess} />

          {/* Example location preference UI in General Settings */}
          <Box sx={{ mt: 3 }}>
            <FormControl fullWidth>
              <InputLabel id="preferred-loc-label">Preferred Location</InputLabel>
              <Select
                labelId="preferred-loc-label"
                value={preferredLocation}
                label="Preferred Location"
                onChange={(e) => handleLocationChange(e.target.value)}
              >
                {locationLoading ? (
                  // While locations are loading, show skeleton or progress
                  <MenuItem value="" disabled>
                    <Skeleton variant="text" width={100} />
                  </MenuItem>
                ) : (
                  availableLocations.map((loc) => (
                    <MenuItem key={loc.id} value={loc.id}>
                      {loc.name}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </Box>
        </FadeTabPanel>

        <FadeTabPanel value={activeTab} index={1}>
          <Integrations 
            currentUser={currentUser} 
            onError={setError} 
            onSuccess={setSuccess} 
          />
        </FadeTabPanel>

        <FadeTabPanel value={activeTab} index={2}>
          <TeamManagement 
            currentUser={currentUser} 
            onError={setError} 
            onSuccess={setSuccess} 
          />
        </FadeTabPanel>

        <FadeTabPanel value={activeTab} index={3}>
          <CompanyInfo 
            currentUser={currentUser} 
            onError={setError} 
            onSuccess={setSuccess} 
          />
        </FadeTabPanel>
      </Paper>
    </Box>
  );
};

export default Settings;
