// AllInvoicesPage.js
import React, { memo, Suspense, useEffect } from 'react';
import { useOutletContext, Outlet } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Alert,
  Stack,
  Typography,
  Container,
  Fade,
  Paper,
  TablePagination
} from '@mui/material';
import SearchFilterSection from './SearchFilterSection';
import InvoiceList from './InvoiceTable';
import { useInvoiceLogic } from './useInvoiceLogic';
import { invoicePageStyles } from './styles';
import ErrorBoundary from '../ErrorBoundary';

const LoadingState = memo(() => (
  <Box sx={invoicePageStyles.loadingContainer}>
    <CircularProgress size={32} thickness={4} />
  </Box>
));

const ErrorState = memo(({ message = 'Failed to load invoices' }) => (
  <Box sx={invoicePageStyles.errorContainer}>
    <Alert severity="error">{message}</Alert>
  </Box>
));

const PageHeader = memo(({ title, subtitle }) => (
  <Box sx={invoicePageStyles.header}>
    <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold' }}>
      {title}
    </Typography>
    {subtitle && (
      <Typography variant="subtitle2" color="text.secondary" sx={{ mt: 0.5 }}>
        {subtitle}
      </Typography>
    )}
  </Box>
));

/**
 * We add TablePagination below the InvoiceList,
 * using the new pagination fields/handlers from invoiceLogic.
 */
const InvoiceContent = memo(({ searchQuery, filterStatus, filteredData, handlers, sortConfig, pagination }) => {
  const { paginatedInvoices = [], sortedInvoices = [], summary } = filteredData || {};
  const {
    handleSearchChange,
    handleFilterChange,
    handleRowClick,
    handleSort,
    handlePageChange,
    handleRowsPerPageChange
  } = handlers || {};

  const { page, rowsPerPage, rowsPerPageOptions } = pagination || {};

  return (
    <Stack spacing={2}>
      <SearchFilterSection
        searchQuery={searchQuery}
        filterStatus={filterStatus}
        onSearchChange={handleSearchChange}
        onFilterChange={handleFilterChange}
      />

      {/** Example: We might show summary stats here */}
      

      <Fade in timeout={400}>
        <Box>
          {/* We pass paginatedInvoices to InvoiceList */}
          <InvoiceList
            invoices={paginatedInvoices}
            totalInvoices={sortedInvoices.length}
            onRowClick={handleRowClick}
            onSort={handleSort}
            sortConfig={sortConfig}
          />

          {/* Pagination UI */}
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <TablePagination
              component="div"
              count={sortedInvoices.length}  // total row count
              page={page}                   // current page
              onPageChange={handlePageChange}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleRowsPerPageChange}
              rowsPerPageOptions={rowsPerPageOptions || [5, 10, 25, 50]}
              labelRowsPerPage="Invoices per page:"
            />
          </Box>
        </Box>
      </Fade>
    </Stack>
  );
});

const AllInvoicesPage = () => {
  // get the invoices from context
  const { invoices = [] } = useOutletContext();
  // call the logic hook
  const invoiceLogic = useInvoiceLogic(invoices);

  useEffect(() => {
    console.log('InvoiceLogic updated:', invoiceLogic);
  }, [invoiceLogic]);

  if (!invoices) {
    return <LoadingState />;
  }
  if (!Array.isArray(invoices)) {
    return <ErrorState />;
  }

  // Destructure the logic
  const { searchQuery, filterStatus, filteredData, handlers, pagination } = invoiceLogic;
  const { sortField, sortDirection } = filteredData || {};

  return (
    <ErrorBoundary fallback={<ErrorState message="Something went wrong" />}>
      <Suspense fallback={<LoadingState />}>
        <Container maxWidth="lg" sx={{ py: 4 }}>
          <PageHeader
            title="Invoices"
            subtitle={
              invoices.length > 0
                ? `Managing ${invoices.length} invoices`
                : 'No invoices found'
            }
          />

          {invoices.length === 0 ? (
            <ErrorState message="No invoices found. New invoices will appear here." />
          ) : (
            <InvoiceContent
              searchQuery={searchQuery}
              filterStatus={filterStatus}
              filteredData={filteredData}
              handlers={handlers}
              sortConfig={{ field: sortField, direction: sortDirection }}
              pagination={pagination}
            />
          )}

          {/* nested child routes if any */}
          <Outlet />
        </Container>
      </Suspense>
    </ErrorBoundary>
  );
};

PageHeader.displayName = 'PageHeader';
InvoiceContent.displayName = 'InvoiceContent';
AllInvoicesPage.displayName = 'AllInvoicesPage';

export default memo(AllInvoicesPage);
