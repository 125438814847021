// useInvoiceLogic.js

import { useState, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import createApi from '../api';

/**
 * @param {Array} initialInvoices - The raw invoice array from your parent or context
 */
export const useInvoiceLogic = (initialInvoices = []) => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const api = useMemo(
    () => createApi(currentUser?.stsTokenManager?.accessToken),
    [currentUser?.stsTokenManager?.accessToken]
  );

  // Ensure invoices is always an array
  const invoices = Array.isArray(initialInvoices) ? initialInvoices : [];

  // ----------------------
  // Internal States
  // ----------------------
  const [searchQuery, setSearchQuery] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  
  // Sorting
  const [sortDirection, setSortDirection] = useState('desc');
  const [sortField, setSortField] = useState('invoiceDate');
  
  // Pagination
  const [page, setPage] = useState(0);           // current page index
  const [rowsPerPage, setRowsPerPage] = useState(10); // how many per page
  
  // ----------------------
  // Handlers
  // ----------------------
  const handleSearchChange = useCallback((event) => {
    setSearchQuery(event?.target?.value || '');
    setPage(0); // Reset page to 0 whenever searching
  }, []);

  const handleFilterChange = useCallback((event) => {
    setFilterStatus(event?.target?.value || 'all');
    setPage(0); // Reset page
  }, []);

  const handleSort = useCallback((field) => {
    setSortField((prevField) => {
      // If sorting same field, flip direction
      if (prevField === field) {
        setSortDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'));
      } else {
        // otherwise reset to ascending
        setSortDirection('asc');
      }
      return field;
    });
    setPage(0);
  }, []);

  const handleRowClick = useCallback((invoice) => {
    if (invoice?.doc_id) {
      // Example: Mark invoice "pending" then navigate
      api.updateInvoiceStatus(invoice.doc_id, 'pending');
      navigate(`/dashboard/invoices/${invoice.doc_id}`);
    }
  }, [api, navigate]);

  // Pagination handlers
  const handlePageChange = useCallback((_, newPage) => {
    setPage(newPage);
  }, []);
  
  const handleRowsPerPageChange = useCallback((event) => {
    const newVal = parseInt(event.target.value, 10) || 5;
    setRowsPerPage(newVal);
    setPage(0); // Reset to first page
  }, []);

  // ----------------------
  // Filter logic
  // ----------------------
  const filteredInvoices = useMemo(() => {
    const lowerCaseQuery = (searchQuery || '').toLowerCase();
    return invoices.filter((inv) => {
      if (!inv) return false;

      // 1) Filter by status if not "all"
      const passStatus = (filterStatus === 'all') || (inv.invoiceStatus === filterStatus);

      // 2) Basic text search across relevant fields
      const dateString = inv.invoiceDate
        ? new Date(inv.invoiceDate).toLocaleDateString('en-US')
        : '';
      const vendor = inv.vendorName || '';
      const invoiceNum = inv.invoiceNumber || '';

      const combined = [vendor, invoiceNum, dateString]
        .map(str => str.toString().toLowerCase())
        .join(' ');

      const passSearch = !lowerCaseQuery || combined.includes(lowerCaseQuery);

      return passStatus && passSearch;
    });
  }, [invoices, searchQuery, filterStatus]);

  // ----------------------
  // Sort logic
  // ----------------------
  const sortedInvoices = useMemo(() => {
    return [...filteredInvoices].sort((a, b) => {
      if (!a || !b) return 0;

      try {
        if (sortField === 'invoiceDate') {
          const dateA = new Date(a.invoiceDate || 0);
          const dateB = new Date(b.invoiceDate || 0);
          return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
        }
        // Otherwise treat as string compare
        const valA = a[sortField] ?? '';
        const valB = b[sortField] ?? '';
        if (sortDirection === 'asc') {
          return valA.toString().localeCompare(valB.toString());
        } else {
          return valB.toString().localeCompare(valA.toString());
        }
      } catch (err) {
        console.error('Sort error:', err);
        return 0;
      }
    });
  }, [filteredInvoices, sortField, sortDirection]);

  // ----------------------
  // Slice for current page
  // ----------------------
  const startIndex = page * rowsPerPage;
  const endIndex   = startIndex + rowsPerPage;
  const paginatedInvoices = sortedInvoices.slice(startIndex, endIndex);

  // ----------------------
  // Summary calculations
  // ----------------------
  const summaryData = useMemo(() => {
    const totalValue = sortedInvoices.reduce((sum, inv) => {
      return sum + (parseFloat(inv?.invoiceTotal || 0) || 0);
    }, 0);
    const pendingCount = sortedInvoices.filter(inv => inv?.invoiceStatus === 'pending').length;

    return {
      totalValue,
      pendingCount,
      totalCount: sortedInvoices.length,
    };
  }, [sortedInvoices]);

  // Return the entire logic object
  return {
    // raw states
    searchQuery,
    filterStatus,

    // filtered + sorted + sliced data
    filteredData: {
      sortField,
      sortDirection,
      sortedInvoices,
      paginatedInvoices, // only the current slice
      summary: summaryData
    },

    // pagination info
    pagination: {
      page,
      rowsPerPage,
      rowsPerPageOptions: [5, 10, 25, 50],
    },

    // all handlers
    handlers: {
      handleSearchChange,
      handleFilterChange,
      handleSort,
      handleRowClick,

      handlePageChange,
      handleRowsPerPageChange
    }
  };
};
