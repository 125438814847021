import React from "react";
import {
  Grid,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  CircularProgress,
  Autocomplete,
  Box
} from "@mui/material";

/**
 * PROPS to pass in from parent:
 *
 * - vendors (Array): e.g. [{ id: 1, name: 'Acme Vendor' }, ...]
 * - loadingVendors (bool): is the vendors list loading?
 * - selectedVendor: the currently selected vendor object or ID
 * - handleVendorChange: (newValue) => { ... }
 *
 * - locations (Array): e.g. [{ id: 1, name: 'Store #1' }, ...]
 * - loadingLocations (bool): is the locations list loading?
 * - selectedLocation: the currently selected location (string or number)
 * - handleLocationChange: (event) => { ... }
 *
 * - invoiceDate, invoiceNumber, vendorName (if needed)
 * - setVendorName, setInvoiceDate, setInvoiceNumber
 * - isInvoiceDone (bool): disable inputs if invoice is done
 * - handleFieldChange: function that sets the local state + unsavedChanges
 */

const InvoiceDetailsForm = ({
  // Invoice fields
  vendorName,
  setVendorName,
  invoiceDate,
  setInvoiceDate,
  invoiceNumber,
  setInvoiceNumber,
  isInvoiceDone,
  handleFieldChange,

  // Vendor auto-complete
  vendors = [],
  loadingVendors = false,
  selectedVendor,
  handleVendorChange,

  // Location dropdown
  locations = [],
  loadingLocations = false,
  selectedLocation,
  handleLocationChange,
}) => {
  return (
    <Grid container spacing={2} mb={2}>

      {/* VENDOR AUTOCOMPLETE */}
      <Grid item xs={12} sm={6}>
        {loadingVendors ? (
          <Box display="flex" alignItems="center" gap={1}>
            <CircularProgress size={24} />
            <span>Loading Vendors...</span>
          </Box>
        ) : (
          <Autocomplete
            // Makes the input take up available width
            fullWidth
            // Automatically highlights the first matching option
            autoHighlight
            // Disable input if invoice is done
            disabled={isInvoiceDone}

            // The full array of vendor objects
            options={vendors}

            // Current selected value
            value={vendors.find((v) => v.vendorid === selectedVendor)}

            // Compare option vs. value by vendorid
            isOptionEqualToValue={(option, value) =>
              option.vendorid === value?.vendorid
            }

            // What text to show for each option in the dropdown
            getOptionLabel={(option) => option.vendorname ?? ""}

            // Shown when no matching options are found
            noOptionsText="No matching vendors"

            // The built-in loading indicator and text
            loading={loadingVendors}
            loadingText="Loading vendors..."

            // Called whenever a user picks a vendor (pass the full object)
            onChange={(_, newValue) => handleVendorChange(newValue)}

            // You can customize how each option is rendered in the list
            renderOption={(props, option) => (
              <MenuItem {...props} key={option.vendorid}>
                {option.vendorname}
              </MenuItem>
            )}

            // Render the text field
            renderInput={(params) => (
              <TextField
                {...params}
                label="Vendor"
                placeholder="Search or select vendor"
                variant="outlined"
                size="small"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingVendors ? <CircularProgress size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        )}
      </Grid>



      {/* INVOICE DATE */}
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          required
          label="Invoice Date"
          type="date"
          value={invoiceDate}
          onChange={handleFieldChange(setInvoiceDate)}
          InputLabelProps={{ shrink: true }}
          inputProps={{ max: new Date().toISOString().split("T")[0] }}
          disabled={isInvoiceDone}
          variant="outlined"
          size="small"
        />
      </Grid>

      {/* INVOICE NUMBER */}
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          required
          label="Invoice Number"
          placeholder="e.g. INV-1001"
          value={invoiceNumber}
          onChange={handleFieldChange(setInvoiceNumber)}
          disabled={isInvoiceDone}
          variant="outlined"
          size="small"
        />
      </Grid>

      {/* LOCATION DROPDOWN */}
      <Grid item xs={12} sm={6}>
        {loadingLocations ? (
          <Box display="flex" alignItems="center" gap={1}>
            <CircularProgress size={24} />
            <span>Loading Locations...</span>
          </Box>
        ) : (
          <Autocomplete
            disabled={isInvoiceDone}
            options={locations} // each item has { id, name, square_location_id, ... }

            // 1) getOptionLabel: parse out everything after the dash
            getOptionLabel={(option) => {
              if (!option.name) return '';
              // Splits at the dash and returns just the trailing portion
              const idx = option.name.indexOf('-');
              if (idx === -1) {
                // fallback if no dash is found
                return option.name.trim();
              }
              // substring the part after "-"
              return option.name.substring(idx + 1).trim();
            }}

            // 2) selected value: finds the location by square_location_id
            value={
              locations.find((loc) => loc.square_location_id === selectedLocation) || null
            }

            // 3) handle the user picking a location
            onChange={(_, newValue) => handleLocationChange(newValue)}

            // 4) let MUI compare by square_location_id
            isOptionEqualToValue={(option, value) =>
              option.square_location_id === value?.square_location_id
            }

            renderInput={(params) => (
              <TextField
                {...params}
                label="Location"
                variant="outlined"
                size="small"
                required
              />
            )}
          />



        )}
      </Grid>

    </Grid>
  );
};

export default InvoiceDetailsForm;
