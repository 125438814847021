import React, { useEffect } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import { Box, CircularProgress } from '@mui/material';

const workerUrl = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.min.js';

const InvoiceDisplay = ({ invoiceData, onLoadingChange }) => {
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;

  // Let the parent know we’re loading as soon as we mount
  useEffect(() => {
    onLoadingChange?.(true);
  }, [onLoadingChange]);

  // Customize toolbar items
  const transform = (slot) => {
    const { ZoomIn, ZoomOut, GoToNextPage, GoToPreviousPage, NumberOfPages, CurrentPageInput } = slot;
    return {
      ...slot,
      ZoomIn,
      ZoomOut,
      GoToNextPage,
      GoToPreviousPage,
      NumberOfPages,
      CurrentPageInput,
    };
  };

  // If no document URL, we’ll just return null or a small message
  if (!invoiceData?.standardized?.documentURL) {
    return null;
  }

  // Once the PDF is fully loaded, we call onLoadingChange(false)
  const handleDocumentLoad = ({ numPages }) => {
    onLoadingChange?.(false);
  };

  return (
    <Box display="flex" flexDirection="column" height="100%" overflow="hidden" sx={{ bgcolor: '#f5f5f5' }}>
      {/* PDF toolbar */}
      <Box>
        <Toolbar>{toolbarPluginInstance.renderDefaultToolbar(transform)}</Toolbar>
      </Box>

      {/* PDF viewer area */}
      <Box flex={1} overflow="hidden">
        <Worker workerUrl={workerUrl}>
          <Viewer
            fileUrl={invoiceData.standardized.documentURL}
            plugins={[toolbarPluginInstance]}
            onDocumentLoad={handleDocumentLoad}
            renderLoader={() => (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress />
              </Box>
            )}
          />
        </Worker>
      </Box>
    </Box>
  );
};

export default InvoiceDisplay;
