import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { 
  collection, 
  query, 
  onSnapshot, 
  doc, 
  updateDoc, 
  deleteDoc, 
  where 
} from 'firebase/firestore';
import { db } from '../firebase';

// Selectors
export const selectAllInvoices = (state) => state.invoices.items;
export const selectInvoiceStatus = (state) => state.invoices.status;
export const selectInvoiceError = (state) => state.invoices.error;
export const selectInvoiceById = (state, invoiceId) =>
  state.invoices.items.find((invoice) => invoice.doc_id === invoiceId);
export const selectInvoicesByStatus = (state, status) =>
  state.invoices.items.filter((invoice) => invoice.invoiceStatus === status);
export const selectInvoicesLoading = (state) =>
  state.invoices.status === 'loading';

// Async thunks
export const setupInvoiceListener = createAsyncThunk(
  'invoices/setupListener',
  async (_, { dispatch }) => {
    try {
      dispatch(setStatus('loading')); // Set loading state
      
      const unsubscribe = onSnapshot(
        query(collection(db, 'invoices')),
        (snapshot) => {
          const invoices = snapshot.docs.map((docSnap) => ({
            doc_id: docSnap.id,
            ...docSnap.data().standardized,
            standardized: docSnap.data().standardized,
            lastUpdated: new Date().toISOString(),
          }));
          dispatch(setInvoices(invoices));
          dispatch(setStatus('succeeded')); // Set succeeded state
        },
        (error) => {
          console.error('Listener error:', error);
          dispatch(setError(error.message));
          dispatch(setStatus('failed')); // Set failed state
        }
      );
      
      return unsubscribe;
    } catch (error) {
      console.error('Setup listener error:', error);
      dispatch(setStatus('failed'));
      throw error;
    }
  }
);

export const saveInvoice = createAsyncThunk(
  'invoices/save',
  async (invoice, { rejectWithValue }) => {
    try {
      console.log('Saving invoice:', invoice);

      // Build invoice data for Firestore
      const invoiceData = {
        standardized: {
          vendorName: invoice.vendorName,
          invoiceNumber: invoice.invoiceNumber,
          invoiceDate: invoice.invoiceDate,
          invoiceTotal: invoice.invoiceTotal,
          invoiceStatus: invoice.invoiceStatus,
          // Convert each item’s numeric fields carefully
          items: invoice.items.map((item) => ({
            ...item,
            unitPrice: parseFloat(item.unitPrice),
            quantity: parseInt(item.quantity, 10),
            packSize: parseInt(item.packSize, 10) || 1,
            totalPrice:
              parseFloat(item.unitPrice) * parseInt(item.quantity, 10),
          })),
          documentURL: invoice.documentURL,
          customerName: invoice.customerName,
          // Store parsed location here
          location: invoice.location || '',
          vendor: invoice.vendor,
          totalTax: invoice.totalTax || 0,
          returns: invoice.returns || '',
          actualAmount: invoice.actualAmount || '',
          deliveryDate: invoice.deliveryDate || '',
          signedBy: invoice.signedBy || '',
          subTotal: invoice.subTotal || 0,
          submittedBy: invoice.submittedBy || '',
          lastUpdated: new Date().toISOString(),
        },
      };

      console.log(
        'About to update Firestore with location =',
        invoiceData.standardized.location
      );

      // Update Firestore document
      await updateDoc(doc(db, 'invoices', invoice.doc_id), invoiceData);

      // Return a copy of what was saved
      const savedInvoice = {
        doc_id: invoice.doc_id,
        ...invoiceData.standardized,
        standardized: invoiceData.standardized,
      };

      console.log('Invoice saved successfully:', savedInvoice);
      return savedInvoice;
    } catch (error) {
      console.error('Save invoice error:', error);
      return rejectWithValue(error.message);
    }
  }
);

export const deleteInvoice = createAsyncThunk(
  'invoices/delete',
  async ({ docId, callback }, { dispatch, rejectWithValue }) => {
    try {
      if (callback) {
        callback();
      }

      // Firestore doc deletion logic (not shown in your snippet)
      // e.g. await deleteDoc(doc(db, 'invoices', docId));

      return docId; // Return the docId for the reducer to update state
    } catch (error) {
      console.error('Error deleting document:', error.response?.data || error.message);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const deleteZeroQuantityItems = createAsyncThunk(
  'invoices/deleteZeroQuantityItems',
  async (invoiceId, { getState, dispatch }) => {
    try {
      const invoice = selectInvoiceById(getState(), invoiceId);
      if (!invoice) throw new Error('Invoice not found');

      const updatedItems = invoice.items.filter(
        (item) => parseInt(item.quantity, 10) > 0
      );

      const updatedInvoice = {
        ...invoice,
        items: updatedItems,
        standardized: {
          ...invoice.standardized,
          items: updatedItems,
        },
      };

      await dispatch(saveInvoice(updatedInvoice)).unwrap();
      return updatedInvoice;
    } catch (error) {
      console.error('Delete zero quantity items error:', error);
      throw error;
    }
  }
);

// Slice
const invoiceSlice = createSlice({
  name: 'invoices',
  initialState: {
    items: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    lastUpdated: null,
  },
  reducers: {
    setInvoices: (state, { payload }) => {
      state.items = payload;
      state.lastUpdated = new Date().toISOString();
    },
    setError: (state, { payload }) => {
      state.error = payload;
    },
    setStatus: (state, { payload }) => {
      state.status = payload; // Update status dynamically
    },
    updateInvoice: (state, { payload }) => {
      const index = state.items.findIndex((inv) => inv.doc_id === payload.doc_id);
      if (index !== -1) {
        state.items[index] = {
          ...state.items[index],
          ...payload.updates,
          lastUpdated: new Date().toISOString(),
        };
      }
    },
    deleteInvoiceFromStore: (state, { payload }) => {
      state.items = state.items.filter((invoice) => invoice.doc_id !== payload);
      state.lastUpdated = new Date().toISOString();
    },
    clearError: (state) => {
      state.error = null;
    },
    resetStatus: (state) => {
      state.status = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      // ========== saveInvoice Lifecycle ==========
      .addCase(saveInvoice.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(saveInvoice.fulfilled, (state, { payload }) => {
        const index = state.items.findIndex((inv) => inv.doc_id === payload.doc_id);
        if (index !== -1) {
          state.items[index] = payload;
        }
        state.status = 'succeeded';
        state.lastUpdated = new Date().toISOString();
      })
      .addCase(saveInvoice.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // ========== deleteInvoice Lifecycle ==========
      .addCase(deleteInvoice.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(deleteInvoice.fulfilled, (state, { payload }) => {
        state.items = state.items.filter((invoice) => invoice.doc_id !== payload);
        state.status = 'succeeded';
        state.lastUpdated = new Date().toISOString();
      })
      .addCase(deleteInvoice.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // ========== deleteZeroQuantityItems Lifecycle ==========
      .addCase(deleteZeroQuantityItems.fulfilled, (state, { payload }) => {
        const index = state.items.findIndex((inv) => inv.doc_id === payload.doc_id);
        if (index !== -1) {
          state.items[index] = payload;
        }
        state.lastUpdated = new Date().toISOString();
      });
  },
});

// Enhanced middleware with better error handling and logging
export const invoiceSyncMiddleware = (store) => (next) => (action) => {
  const result = next(action);

  if (action.type === 'invoices/updateInvoice') {
    const invoice = store
      .getState()
      .invoices.items.find((inv) => inv.doc_id === action.payload.doc_id);

    if (invoice) {
      console.log('Middleware triggering save for invoice:', invoice);
      store
        .dispatch(saveInvoice(invoice))
        .unwrap()
        .then(() => console.log('Middleware save successful'))
        .catch((error) => {
          console.error('Middleware save failed:', error);
          store.dispatch(setError(error.message));
        });
    }
  }

  return result;
};

// Exports
export const {
  setInvoices,
  setError,
  updateInvoice,
  clearError,
  resetStatus,
  setStatus,
  deleteInvoiceFromStore,
} = invoiceSlice.actions;

export default invoiceSlice.reducer;
