// Default margin ranges remain unchanged
export const defaultMarginRanges = [
  { min: 0, max: 0.2, margin: 45 },
  { min: 0.2, max: 0.4, margin: 45 },
  { min: 0.4, max: 0.6, margin: 45 },
  { min: 0.6, max: 0.8, margin: 45 },
  { min: 0.8, max: 1, margin: 45 },
  { min: 1.01, max: 3, margin: 33 },
  { min: 3.01, max: 5, margin: 31 },
  { min: 5.01, max: 9, margin: 26 },
  { min: 9.01, max: 12, margin: 24 },
  { min: 12.01, max: Infinity, margin: 20 }
];

// Validation function remains unchanged
const validateMarginRanges = (ranges) => {
  if (!Array.isArray(ranges) || ranges.length === 0) {
    console.warn('Invalid or empty margin ranges. Falling back to default ranges.');
    return false;
  }

  const isValid = ranges.every(range =>
    typeof range.min === 'number' &&
    (typeof range.max === 'number' || range.max === null || range.max === Infinity) &&
    typeof range.margin === 'number'
  );

  if (!isValid) {
    console.warn('Margin ranges contain invalid entries. Falling back to default ranges.');
  }

  return isValid;
};

/**
 * Rounds the given price value up to the nearest hundredth that ends in a 9.
 * Examples:
 *  - 3.20 → 3.29
 *  - 3.94 → 3.99
 *  - 3.99 → 3.99 (already ends in 9)
 *  - 3.00 → 3.09
 */
const roundToHundredthEndingInNine = (value) => {
  if (value <= 0) return 0;

  // Truncate down to two decimals
  let truncated = Math.floor(value * 100) / 100; // e.g. 3.94 → 3.94; 3.999 → 3.99

  const hundredths = Math.round(truncated * 100); // Convert to integer hundredths
  const lastDigit = hundredths % 10; // Check the last decimal place

  if (lastDigit === 9) {
    // Already ends in .x9
    return parseFloat(truncated.toFixed(2));
  } else {
    // Find how much we need to add to get to the next .x9
    // Example: if lastDigit=4, we need to add (9-4)=5 hundredths => 0.05
    const difference = (9 - lastDigit) / 100;
    const rounded = truncated + difference;
    return parseFloat(rounded.toFixed(2));
  }
};

// Updated price calculation function
export const calculatePrices = (localItem, categoryData) => {
  console.log('[Price Calculation] Category Data:', categoryData);

  const packSize = parseFloat(localItem.packSize) || 0;
  const unitPrice = parseFloat(localItem.unitPrice) || 0;

  if (packSize <= 0 || unitPrice <= 0) {
    console.warn('[Price Calculation] Invalid packSize or unitPrice. Returning 0.');
    return 0;
  }

  const costPerUnit = unitPrice / packSize;
  console.log('[Price Calculation] costPerUnit:', costPerUnit);

  const marginRanges = validateMarginRanges(categoryData?.marginRanges)
    ? categoryData.marginRanges
    : defaultMarginRanges;

  console.log('[Price Calculation] Using Margin Ranges:', marginRanges);

  const marginRange = marginRanges.find(({ min, max }) =>
    costPerUnit >= min && costPerUnit <= (max ?? Infinity)
  ) || { margin: 0 };

  const margin = marginRange.margin;
  console.log('[Price Calculation] Selected Margin:', margin, '%');

  // markupPrice = costPerUnit / (1 - margin/100)
  const markupPrice = costPerUnit / (1 - margin / 100);
  console.log('[Price Calculation] Markup Price (before rounding):', markupPrice);

  const finalPrice = roundToHundredthEndingInNine(markupPrice);
  console.log('[Price Calculation] Final Calculated Price:', finalPrice);

  return finalPrice;
};

// Price difference calculation remains unchanged
export const calculatePriceDifference = (currentPrice, calculatedPrice) => {
  const parsedCurrentPrice = parseFloat(currentPrice) || 0;
  const priceDifference = parsedCurrentPrice > 0
    ? ((calculatedPrice - parsedCurrentPrice) / parsedCurrentPrice) * 100
    : 0;
  return priceDifference;
};

export const getRecommendation = (priceDifference) => {
  console.log('[Recommendation] Price Difference:', priceDifference);

  // Define thresholds and corresponding recommendations
  // priceDifference is in percentage terms (e.g., 10 means 10% higher than recommended)
  if (priceDifference > 20) {
    return 'Significantly Increase (Your current price is much lower than recommended)';
  } else if (priceDifference > 10) {
    return 'Moderately Increase (Your current price is lower than recommended)';
  } else if (priceDifference > 6) {
    return 'Slightly Increase (Your current price could be higher)';
  } else if (priceDifference < -20) {
    return 'Significantly Decrease (Your current price is much higher than recommended)';
  } else if (priceDifference < -10) {
    return 'Moderately Decrease (Your current price is higher than recommended)';
  } else if (priceDifference < -6) {
    return 'Slightly Decrease (Your current price could be lower)';
  } else {
    return 'Keep the same (Your price is close to the recommended range)';
  }
};

