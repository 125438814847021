import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useLayoutEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveInvoice } from "../../../redux/invoiceSlice";
import {
  Paper,
  Box,
  Button,
  Divider,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText
} from "@mui/material";

import SendIcon from "@mui/icons-material/Send";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RefreshIcon from "@mui/icons-material/Refresh";
import ReportIcon from "@mui/icons-material/Report";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import createApi from "../api";
import createInventoryApi from "../inventoryApi";
import ProductItem from "./ProductItem";
import SearchProductDialog from "../SearchProductDialog";
import SubmitOverviewDialog from "../SubmitOverviewDialog";
import EnhancedAddItemDialog from "../EnhancedAddItemDialog";

import InvoiceAppBar from "./InvoiceAppBar";
import InvoiceDetailsForm from "./InvoiceDetailsForm";

/** Utility to determine if a line item is "complete" enough to submit. */
const isItemComplete = (item) => {
  return (
    item.description &&
    item.quantity &&
    item.packSize &&
    item.squareUp?.itemId
  );
};

const ProductsList = ({
  invoiceData,
  currentUser,
  docId,
  onInvoiceDeleted,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // ====== Invoice fields ======
  const [items, setItems] = useState([]);
  const [vendorName, setVendorName] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);

  // ====== Vendor selection ======
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [loadingVendors, setLoadingVendors] = useState(false);

  // ====== Location selection ======
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [loadingLocations, setLoadingLocations] = useState(false);

  // ====== Invoice status ======
  const [invoiceStatus, setInvoiceStatus] = useState("new");
  const isInvoiceDone = invoiceStatus === "done";

  // ====== Dialog states ======
  const [isSearchDialogOpen, setIsSearchDialogOpen] = useState(false);
  const [isOverviewDialogOpen, setIsOverviewDialogOpen] = useState(false);
  const [isAddItemDialogOpen, setIsAddItemDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  // ====== Selected item (for searching/editing) ======
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [initialSearchTerm, setInitialSearchTerm] = useState("");
  const [currentPackSize, setCurrentPackSize] = useState("");

  // ====== Loading & Notifications ======
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // ====== Incomplete items tracking ======
  const [incompleteItems, setIncompleteItems] = useState([]);

  // ====== Unsaved changes & save state ======
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveStatus, setSaveStatus] = useState("");
  const [lastSaved, setLastSaved] = useState(null);

  // ====== Scrolling ======
  const scrollContainerRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  // ====== "Actions" menu anchor ======
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  // ====== API instances ======
  const api = createApi(currentUser?.stsTokenManager?.accessToken);
  const inventoryApi = createInventoryApi(currentUser?.stsTokenManager?.accessToken);

  // ====== Load invoice data into local state ======
  useEffect(() => {
    if (invoiceData) {
      setItems(invoiceData.standardized.items || []);
      setVendorName(invoiceData.standardized.vendorName || "");
      setInvoiceNumber(invoiceData.standardized.invoiceNumber || "");

      if (invoiceData.standardized.invoiceDate) {
        const date = new Date(invoiceData.standardized.invoiceDate);
        setInvoiceDate(date.toISOString().split("T")[0]);
      }

      setInvoiceStatus(invoiceData.standardized.invoiceStatus || "new");
      setTotalAmount(invoiceData.standardized.invoiceTotal || 0);

      // If the invoice stored location, restore it
      if (invoiceData.standardized.location) {
        setSelectedLocation(String(invoiceData.standardized.location));
      }
      // If the invoice stored a vendor ID, restore it
      if (invoiceData.standardized.vendor) {
        setSelectedVendor(invoiceData.standardized.vendor);
      }
    }
  }, [invoiceData]);

  // ====== Restore scroll position after re-render ======
  useLayoutEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = scrollPosition;
    }
  }, [scrollPosition]);

  // ====== Prompt user if they try to close tab with unsaved changes ======
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue =
          "You have unsaved changes. Are you sure you want to leave?";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [hasUnsavedChanges]);

  // ====== Load vendors & locations on mount ======
  useEffect(() => {
    setLoadingVendors(true);
    setLoadingLocations(true);

    // GET /vendors
    inventoryApi
      .getAllVendors()
      .then((res) => {
        console.log(res.data)
        setVendors(res.data || []);
      })
      .catch((err) => {
        console.error("Error fetching vendors:", err);
      })
      .finally(() => setLoadingVendors(false));

    // GET /locations
    inventoryApi
      .getAllLocations()
      .then((res) => {
        console.log(res.data)
        setLocations(res.data || []);
      })
      .catch((err) => {
        console.error("Error fetching locations:", err);
      })
      .finally(() => setLoadingLocations(false));
  }, []);

  // ====== Field changes ======
  const handleFieldChange = (setter) => (event) => {
    setter(event.target.value);
    setHasUnsavedChanges(true);
  };

  const handleVendorChange = (vendor) => {
    console.log("Selected vendor changed to:", vendor);
  
    if (vendor && typeof vendor === "object") {
      // Store vendorid as a string, for example
      setSelectedVendor(vendor.vendorid);
      setVendorName(vendor.vendorname);
    } else {
      setSelectedVendor("");
      setVendorName("");
    }
  
    setHasUnsavedChanges(true);
  };
  
  
  // Instead of expecting an event, we receive the full `location` object or `null`:
  const handleLocationChange = (locObj) => {
    if (locObj && locObj.square_location_id) {
      console.log("Selected location object:", locObj);
      // We store the location’s square_location_id as the selectedLocation
      setSelectedLocation(locObj.square_location_id);
    } else {
      // If cleared
      setSelectedLocation("");
    }
    setHasUnsavedChanges(true);
  };



  const saveScrollPosition = useCallback(() => {
    if (scrollContainerRef.current) {
      setScrollPosition(scrollContainerRef.current.scrollTop);
    }
  }, []);

  // ====== "Actions" menu logic ======
  const handleActionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleActionsClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteZeroQty = () => {
    handleActionsClose();
    // TODO: remove items with 0 quantity
  };

  const handleReportInvoice = async () => {
    handleActionsClose();
    await api.updateInvoiceStatus(docId, "reported");
    navigate("/dashboard/invoices");
  };

  const handleRefreshData = () => {
    handleActionsClose();
    // TODO: re-fetch invoiceData from the backend
  };

  // ====== Save invoice ======
  const handleSaveInvoice = async () => {
    setIsLoading(true);
    setError(null);
    setSaveStatus("Saving...");

    // Calculate total
    const calculatedTotal = items.reduce((sum, item) => {
      return (
        sum +
        (parseFloat(item.unitPrice) || 0) * (parseInt(item.quantity, 10) || 0)
      );
    }, 0);

    console.log("Saving invoice with location:", selectedLocation);
    console.log("Saving invoice with vendor:", selectedVendor);

    // Prepare updated invoice data
    const updatedInvoiceData = {
      ...invoiceData,
      doc_id: docId,
      invoiceDate,
      invoiceNumber,
      invoiceStatus,
      invoiceTotal: calculatedTotal,

      // We store numeric IDs for vendor/location if present
      location: selectedLocation,
      vendor: selectedVendor,

      vendorName, // If you still want to keep 'vendorName' as separate text

      // store them in standardized as well
      standardized: {
        ...invoiceData.standardized,
        vendorName,
        invoiceDate,
        invoiceNumber,
        invoiceStatus,
        invoiceTotal: calculatedTotal,
        items: items.map((item) => ({
          ...item,
          unitPrice: parseFloat(item.unitPrice) || 0,
          quantity: parseInt(item.quantity, 10) || 0,
          packSize: parseInt(item.packSize, 10) || 1,
          totalPrice:
            (parseFloat(item.unitPrice) || 0) *
            (parseInt(item.quantity, 10) || 0),
        })),
      },
      actualAmount: calculatedTotal,
      customerName: invoiceData.customerName || "FIVE SPICE GROCERY",
      lastModified: new Date().toISOString(),
      modifiedBy: currentUser?.displayName || "",
      items: items.map((item) => ({
        ...item,
        unitPrice: parseFloat(item.unitPrice) || 0,
        quantity: parseInt(item.quantity, 10) || 0,
        packSize: parseInt(item.packSize, 10) || 1,
        totalPrice:
          (parseFloat(item.unitPrice) || 0) *
          (parseInt(item.quantity, 10) || 0),
      })),
    };

    try {
      await dispatch(saveInvoice(updatedInvoiceData)).unwrap();
      setHasUnsavedChanges(false);
      setLastSaved(new Date().toISOString());
      setSaveStatus("Saved");
      setError("Invoice successfully saved.");
    } catch (err) {
      console.error("Error saving invoice:", err);
      setSaveStatus("Save failed");
      setError("Failed to save invoice. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // ====== Item updates ======
  const handleUpdateItem = useCallback(
    async ({ invoiceId, itemIndex, updatedItemData }) => {
      if (invoiceStatus === "done") {
        console.log("Invoice is done; can't update");
        return;
      }
      saveScrollPosition();
      setIsLoading(true);
      setError(null);

      try {
        // Example of updating item in your backend
        const result = await api.updateItem(invoiceId, itemIndex, updatedItemData);

        // Optionally update product pricing in Square
        await api.updateProductPrice(
          updatedItemData.squareUp.variationId,
          updatedItemData.squareUp.price,
          updatedItemData.squareUp.categoryId
        );

        setItems((prevItems) => {
          const newItems = [...prevItems];
          newItems[itemIndex] = result.updatedItemTableDoc;
          return newItems;
        });
      } catch (err) {
        console.error("Error updating item:", err);
        setError("Failed to update item. Please try again.");
      } finally {
        setIsLoading(false);
      }
    },
    [api, invoiceStatus, saveScrollPosition]
  );

  // ====== Open product search for line item ======
  const handleOpenSearch = useCallback(
    ({ itemIndex, initialSearchTerm, currentPackSize }) => {
      if (invoiceStatus === "done") return;
      setSelectedItemIndex(itemIndex);
      setInitialSearchTerm(initialSearchTerm);
      setCurrentPackSize(currentPackSize);
      setIsSearchDialogOpen(true);
    },
    [invoiceStatus]
  );

  // Called after user selects a product in the search
  const handleSelectSquareProduct = useCallback(
    (squareProduct) => {
      if (invoiceStatus === "done") return;
      saveScrollPosition();

      setItems((prevItems) => {
        const newItems = [...prevItems];
        const selectedItem = newItems[selectedItemIndex];

        const updatedItem = {
          ...selectedItem,
          squareUp: {
            categoryId: squareProduct.categoryId,
            itemId: squareProduct.id,
            itemName: squareProduct.name,
            variationId: squareProduct.variations?.[0]?.id || "",
            variationName: squareProduct.variations?.[0]?.name || "",
            price: squareProduct.variations?.[0]?.price_money?.amount || 0,
            barcode: squareProduct.barcode || squareProduct.sku || "",
          },
          packSize: selectedItem.packSize || currentPackSize,
        };
        newItems[selectedItemIndex] = updatedItem;
        return newItems;
      });
      setIsSearchDialogOpen(false);
    },
    [selectedItemIndex, invoiceStatus, saveScrollPosition, currentPackSize]
  );

  // ====== Delete a single item ======
  const handleDeleteItem = useCallback(
    async ({ itemIndex }) => {
      if (invoiceStatus === "done") {
        console.log("Invoice is done, cannot delete item");
        return;
      }
      saveScrollPosition();
      setIsLoading(true);
      setError(null);

      setItems((prevItems) => {
        const updatedItems = prevItems.filter((_, i) => i !== itemIndex);

        // If no items left => delete the entire invoice
        if (updatedItems.length === 0) {
          api
            .deleteInvoice(docId, dispatch)
            .then(() => {
              setError("Last item deleted. Invoice removed.");
              if (onInvoiceDeleted) onInvoiceDeleted();
            })
            .catch((err) => {
              console.error("Error deleting invoice:", err);
              setError(`Failed to delete invoice: ${err.message}`);
            });
        }
        return updatedItems;
      });

      // remove from incomplete if needed
      setIncompleteItems((prev) =>
        prev.filter((i) => i !== itemIndex).map((i) => (i > itemIndex ? i - 1 : i))
      );

      try {
        await api.deleteItem(docId, itemIndex);
        setError("Item successfully deleted");
      } catch (err) {
        console.error("Error deleting item via API:", err);
        setError(`Failed to delete item: ${err.message}. Please try again.`);
      } finally {
        setIsLoading(false);
      }
    },
    [api, docId, invoiceStatus, saveScrollPosition, onInvoiceDeleted, dispatch]
  );

  // ====== Delete entire invoice ======
  const handleDeleteInvoice = async () => {
    setIsLoading(true);
    setError(null);
    try {
      await api.deleteInvoice(docId, dispatch);
      setError("Invoice successfully deleted");
      if (onInvoiceDeleted) onInvoiceDeleted();
    } catch (err) {
      console.error("Error deleting invoice:", err);
      setError("Failed to delete invoice. Please try again.");
    } finally {
      setIsLoading(false);
      setIsDeleteDialogOpen(false);
    }
  };

  // ====== Add new item ======
  const handleAddItem = useCallback(
    (newItem) => {
      saveScrollPosition();
      const formattedItem = {
        description: newItem.description || "",
        quantity: parseFloat(newItem.quantity) || 0,
        unitPrice: parseFloat(newItem.unitPrice) || 0,
        packSize: newItem.packSize || "",
        totalPrice:
          (parseFloat(newItem.quantity) || 0) * (parseFloat(newItem.unitPrice) || 0),
        squareUp: newItem.squareUp || {
          itemId: "",
          itemName: "",
          variationId: "",
          variationName: "",
          price: 0,
          barcode: "",
        },
      };
      setItems((prev) => [...prev, formattedItem]);
      setIsAddItemDialogOpen(false);
      setHasUnsavedChanges(true);
    },
    [saveScrollPosition]
  );

  // ====== Submit invoice to Square ======
  const handleConfirmSubmitToSquare = async () => {
    setIsLoading(true);
    setError(null);
    const currentDate = new Date().toISOString();
    try {
      const invoiceDataForSquare = {
        docId,
        vendorName,
        locationId: selectedLocation,
        invoiceDate,
        invoiceNumber,
        totalAmount,
        items: items.map((item) => ({
          ...item,
          unitPrice: parseFloat(item.unitPrice),
          quantity: parseInt(item.quantity, 10),
          packSize: parseInt(item.packSize, 10) || 1,
        })),
        submittedBy: currentUser.displayName,
        submittedOn: currentDate,
      };

      await api.submitInvoiceToSquare(invoiceDataForSquare, currentUser);
      await api.updateInvoiceStatus(docId, "done");

      setInvoiceStatus("done");
      setError("Invoice successfully submitted to Square and marked as done");
      setIncompleteItems([]);
      setIsOverviewDialogOpen(false);
      navigate("/dashboard/invoices");
    } catch (err) {
      console.error("Error submitting invoice:", err);
      setError("Failed to submit invoice. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Validate items before calling confirm
  const handleSubmitToSquare = useCallback(() => {
    if (invoiceStatus === "done") {
      console.log("Invoice is already done");
      return;
    }

    // 1) Check if location is selected
    if (!selectedLocation) {
      setError("Please select a location before submitting the invoice.");
      return;
    }

    // 2) Validate items
    const incomplete = items.reduce((acc, item, idx) => {
      if (!isItemComplete(item)) acc.push(idx);
      return acc;
    }, []);
    if (incomplete.length > 0) {
      setIncompleteItems(incomplete);
      setError(
        `Please complete all item details before submitting. Incomplete items: ${incomplete
          .map((i) => i + 1)
          .join(", ")}`
      );
      return;
    }

    // 3) If location is set and all items are complete, proceed
    setIsOverviewDialogOpen(true);
  }, [items, invoiceStatus, selectedLocation]);


  // ===== Render =====
  return (
    <Paper
      elevation={3}
      sx={{
        height: "auto",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        maxWidth: "100%",
        margin: "auto",
      }}
    >
      {/* Top App Bar */}
      <InvoiceAppBar
        saveStatus={saveStatus}
        lastSaved={lastSaved}
        hasUnsavedChanges={hasUnsavedChanges}
        handleSaveInvoice={handleSaveInvoice}
        isLoading={false}
      />

      {/* Invoice Details + Vendor & Location */}
      <Box p={2}>
        <InvoiceDetailsForm
          vendorName={vendorName}
          setVendorName={setVendorName}
          invoiceDate={invoiceDate}
          setInvoiceDate={setInvoiceDate}
          invoiceNumber={invoiceNumber}
          setInvoiceNumber={setInvoiceNumber}
          isInvoiceDone={isInvoiceDone}
          handleFieldChange={handleFieldChange}

          // location props
          locations={locations}
          selectedLocation={selectedLocation}
          handleLocationChange={handleLocationChange}
          loadingLocations={loadingLocations}

          // vendor props
          vendors={vendors}
          selectedVendor={selectedVendor}
          handleVendorChange={handleVendorChange}
          loadingVendors={loadingVendors}
        />
      </Box>
      {/* List of Items */}
      <Box
        ref={scrollContainerRef}
        sx={{
          flex: "1 1 auto",
          px: 2,
          maxHeight: '50vh',      // or something like '50vh' or 'calc(100vh - 200px)'
          overflowY: "auto",   // scroll only vertically if content overflows
        }}
      >
        {items.map((item, index) => (
          <ProductItem
            key={`${item.id}-${index}`}
            item={item}
            selectedVendor={selectedVendor}
            invoiceId={docId}
            itemIndex={index}
            onSearch={handleOpenSearch}
            onUpdate={handleUpdateItem}
            onDelete={handleDeleteItem}
            isIncomplete={incompleteItems.includes(index)}
            disabled={isInvoiceDone}
            currentUser={currentUser}
          />
        ))}
      </Box>


      <Divider sx={{ my: 2 }} />

      {/* Bottom Buttons */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 2, mt: 2 }}
      >
        {/* Left: Submit */}
        <Box>
          <Button
            variant="contained"
            color="error"
            onClick={handleSubmitToSquare}
            disabled={isLoading || isInvoiceDone}
            startIcon={<SendIcon />}
          >
            Submit to Square
          </Button>
        </Box>

        {/* Right: More Menu */}
        <Box>
          <Button
            variant="outlined"
            color="primary"
            onClick={(e) => setAnchorEl(e.currentTarget)}
            startIcon={<MoreVertIcon />}
            disabled={isLoading}
          >
            Actions
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleActionsClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            transformOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            {hasUnsavedChanges && (
              <MenuItem
                onClick={() => {
                  handleSaveInvoice();
                  handleActionsClose();
                }}
              >
                <ListItemIcon>
                  <SaveIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Save Invoice</ListItemText>
              </MenuItem>
            )}

            <MenuItem
              onClick={() => {
                setIsAddItemDialogOpen(true);
                handleActionsClose();
              }}
            >
              <ListItemIcon>
                <AddIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Add Item</ListItemText>
            </MenuItem>

            <MenuItem
              onClick={() => {
                setIsDeleteDialogOpen(true);
                handleActionsClose();
              }}
            >
              <ListItemIcon>
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Delete Invoice</ListItemText>
            </MenuItem>

            <MenuItem onClick={handleDeleteZeroQty}>
              <ListItemIcon>
                <RemoveCircleOutlineIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Delete Zero Qty Items</ListItemText>
            </MenuItem>

            <MenuItem onClick={handleReportInvoice}>
              <ListItemIcon>
                <ReportIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Report Invoice</ListItemText>
            </MenuItem>

            <MenuItem onClick={handleRefreshData}>
              <ListItemIcon>
                <RefreshIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Refresh Invoice Data</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
      </Box>

      {/* Dialogs */}
      <SearchProductDialog
        open={isSearchDialogOpen}
        onClose={() => setIsSearchDialogOpen(false)}
        onSelect={handleSelectSquareProduct}
        api={api}
        isLoading={isLoading}
        initialSearchTerm={initialSearchTerm}
      />
      <EnhancedAddItemDialog
        open={isAddItemDialogOpen}
        onClose={() => setIsAddItemDialogOpen(false)}
        onAddItem={handleAddItem}
        onSelect={handleSelectSquareProduct}
        api={api}
        currentUser={currentUser}
        docId={docId}
        initialSearchTerm=""
        isLoading={isLoading}
      />

      <SubmitOverviewDialog
        open={isOverviewDialogOpen}
        onClose={() => setIsOverviewDialogOpen(false)}
        items={items}
        invoiceTotal={totalAmount}
        onConfirm={handleConfirmSubmitToSquare}
      />

      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this invoice? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteInvoice} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
      >
        <Alert
          onClose={() => setError(null)}
          severity={
            error && error.toLowerCase().includes("successfully")
              ? "success"
              : "error"
          }
        >
          {error}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default React.memo(ProductsList);
