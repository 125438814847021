import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setupInvoiceListener, selectAllInvoices } from '../../redux/invoiceSlice';
import { Box, Alert, Container, Button, Skeleton } from '@mui/material';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import ResponsiveAppBar from '../Navbar/ResponsiveAppBar';
import { useCustomerData } from '../Dashboard/Hooks/useCustomerData';
import { useAuth } from '../../context/AuthContext';
import createApi from './api';
import creatInventoryApi from './inventoryApi'
import ToolbarComponent from './Toolbar';
import LoadingOverlay from '../../LoadingOverlay';

const InvoiceDashboard = () => {
    // Auth and Navigation setup
    const { currentUser } = useAuth();
    const { customerData, error: customerError } = useCustomerData(currentUser);
    const dispatch = useDispatch();

    // State selectors for invoices and subscription status
    const invoices = useSelector(selectAllInvoices) || [];
    const invoiceStatus = useSelector(state => state.invoices.status);
    const error = useSelector(state => state.invoices.error);
    const unsubscribe = useSelector(state => state.invoices.listener);

    // Local component state
    const [isDrawerOpen, setIsDrawerOpen] = useState(true);

    // Create API instance with user token for authorized requests
    const api = useMemo(() => 
        createApi(currentUser?.stsTokenManager?.accessToken), 
        [currentUser?.stsTokenManager?.accessToken] // More specific dependency
    );

    const inventoryapi = useMemo(() => 
        creatInventoryApi(currentUser?.stsTokenManager?.accessToken), 
        [currentUser?.stsTokenManager?.accessToken] // More specific dependency
    );

    // Effect to set up the invoice listener when user logs in and the status is idle
    useEffect(() => {
        let isSubscribed = true;

        if (currentUser && invoiceStatus === 'idle' && isSubscribed) {
            dispatch(setupInvoiceListener());
        }

        // Cleanup: Unsubscribe from the listener when component unmounts
        return () => {
            isSubscribed = false;
            if (typeof unsubscribe === 'function') {
                unsubscribe();
            }
        };
    }, [currentUser, invoiceStatus, dispatch, unsubscribe]);

    // Toggle drawer open/close
    const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

    // Show error if customer data failed to load
    if (customerError) {
        return (
            <Box sx={{ p: 3 }}>
                <Alert severity="error">
                    Failed to load user data: {customerError.message}
                </Alert>
            </Box>
        );
    }

    const renderContent = () => {
        if (invoiceStatus === 'loading') {
            return (
                <LoadingOverlay />
            );
        }

        if (invoiceStatus === 'failed') {
            return (
                <Alert 
                    severity="error"
                    action={
                        <Button 
                            color="inherit" 
                            size="small"
                            onClick={() => dispatch(setupInvoiceListener())}
                        >
                            Retry
                        </Button>
                    }
                >
                    {error}
                </Alert>
            );
        }

        return (
            <Outlet 
                context={{ 
                    invoices, 
                    api,
                    currentUser,
                    customerData 
                }} 
            />
        );
    };

    return (
        <Box sx={{ 
            height: '100vh',
            display: 'flex', 
            flexDirection: 'column', 
            bgcolor: 'background.default',
            overflow: 'hidden'
        }}>
            <ResponsiveAppBar user={customerData} />
            
            <Box sx={{ 
                display: 'flex', 
                flexGrow: 1, 
                height: 'calc(100% - 64px)', // Adjust based on AppBar height
                overflow: 'hidden'
            }}>
                <ToolbarComponent
                    isOpen={isDrawerOpen}
                    toggleDrawer={toggleDrawer}
                    inventoryApi={inventoryapi}
                    api={api}
                />
                <Box sx={{ 
                    flexGrow: 1, 
                    p: 3,
                    overflow: 'auto'
                }}>
                    <Container maxWidth="xl" sx={{ height: '100%' }}>
                        {renderContent()}
                    </Container>
                </Box>
            </Box>
        </Box>
    );
};

export default InvoiceDashboard;
