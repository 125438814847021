// InvoiceList.js
import React, { memo } from 'react';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Stack,
  Tooltip,
  Chip,
  Fade,
  Grow,
} from '@mui/material';
import {
  ArrowForward,
  CalendarToday,
  ArrowUpward,
  ArrowDownward
} from '@mui/icons-material';

// A dictionary of status styles/icons
const STATUS_CONFIG = {
  pending: {
    color: 'warning',
    icon: '⏳',
    label: 'Pending',
    backgroundColor: 'warning.light'
  },
  done: {
    color: 'success',
    icon: '✓',
    label: 'Completed',
    backgroundColor: 'success.light'
  },
  reported: {
    color: 'info',
    icon: '📊',
    label: 'Reported',
    backgroundColor: 'info.light'
  },
  new: {
    color: 'primary',
    icon: '🆕',
    label: 'New',
    backgroundColor: 'primary.light'
  }
};

/** =========================================
 * TableHeader
 * 
 * Renders column headers (Date, Vendor, Status, Amount)
 * Clicking a header calls onSort(field).
 * ========================================== */
const TableHeader = memo(({ onSort, sortConfig = { field: null, direction: 'asc' } }) => {
  // Renders an arrow indicator if a column is the active sort
  const SortIndicator = ({ field }) => {
    if (sortConfig.field !== field) return null;
    return sortConfig.direction === 'asc'
      ? <ArrowUpward fontSize="small" />
      : <ArrowDownward fontSize="small" />;
  };

  return (
    <Paper
      elevation={0}
      sx={{
        p: 2,
        mb: 1,
        backgroundColor: 'background.paper',
        borderBottom: 1,
        borderColor: 'divider',
        borderRadius: 2,
      }}
    >
      <Stack 
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        {/* Left column headers */}
        <Stack direction="row" spacing={3} alignItems="center" sx={{ flex: 1 }}>
          <Box sx={{ minWidth: 110 }}>
            <Typography
              variant="subtitle2"
              onClick={() => onSort('invoiceDate')}
              sx={{
                cursor: 'pointer',
                display: 'inline-flex',
                alignItems: 'center',
                gap: 0.5,
                fontWeight: 600,
                '&:hover': { color: 'primary.main' },
              }}
            >
              Date <SortIndicator field="invoiceDate" />
            </Typography>
          </Box>

          <Box sx={{ flex: 1 }}>
            <Typography
              variant="subtitle2"
              onClick={() => onSort('vendorName')}
              sx={{
                cursor: 'pointer',
                display: 'inline-flex',
                alignItems: 'center',
                gap: 0.5,
                fontWeight: 600,
                '&:hover': { color: 'primary.main' },
              }}
            >
              Vendor <SortIndicator field="vendorName" />
            </Typography>
          </Box>
        </Stack>

        {/* Right column headers */}
        <Stack direction="row" spacing={3} alignItems="center">
          <Box sx={{ minWidth: 120, textAlign: 'center' }}>
            <Typography
              variant="subtitle2"
              onClick={() => onSort('invoiceStatus')}
              sx={{
                cursor: 'pointer',
                display: 'inline-flex',
                alignItems: 'center',
                gap: 0.5,
                fontWeight: 600,
                '&:hover': { color: 'primary.main' },
              }}
            >
              Status <SortIndicator field="invoiceStatus" />
            </Typography>
          </Box>

          <Box sx={{ minWidth: 80, textAlign: 'right' }}>
            <Typography
              variant="subtitle2"
              onClick={() => onSort('invoiceTotal')}
              sx={{
                cursor: 'pointer',
                display: 'inline-flex',
                alignItems: 'center',
                gap: 0.5,
                fontWeight: 600,
                '&:hover': { color: 'primary.main' },
              }}
            >
              Amount <SortIndicator field="invoiceTotal" />
            </Typography>
          </Box>

          {/* Extra space for icons in the row */}
          <Box sx={{ width: 32 }} />
        </Stack>
      </Stack>
    </Paper>
  );
});

/** =========================================
 * StatusChip
 * 
 * A small colored chip with icon + label 
 * based on invoice's status.
 * ========================================== */
const StatusChip = memo(({ status }) => {
  const config = STATUS_CONFIG[status] || STATUS_CONFIG.pending;

  return (
    <Chip
      size="small"
      label={config.label}
      icon={<span style={{ fontSize: '1rem' }}>{config.icon}</span>}
      sx={{
        backgroundColor: config.backgroundColor,
        color: (theme) => theme.palette[config.color].contrastText,
        minWidth: 85,
        fontWeight: 'bold',
        borderRadius: 1,
        '& .MuiChip-icon': {
          fontSize: 16,
          marginLeft: '4px',
        },
      }}
    />
  );
});

/** =========================================
 * InvoiceRow
 * 
 * A single invoice row:
 * - Date on the left
 * - Vendor info in the middle
 * - Status + total + arrow on the right
 * - Grows in with a slight stagger by index
 * ========================================== */
const InvoiceRow = memo(({ invoice, index, onRowClick }) => {
  const rowKey = invoice?.doc_id || `invoice-row-${index}`;

  // Attempt to parse invoice date
  const dateLabel = (() => {
    try {
      return invoice.invoiceDate
        ? new Date(invoice.invoiceDate.replace(/-/g, '/')).toLocaleDateString('en-CA')
        : 'No date';
    } catch (error) {
      console.error('Date parsing error:', error);
      return 'Invalid date';
    }
  })();

  return (
    <Grow
      in
      style={{ transformOrigin: '0 0 0' }}
      timeout={300 + index * 50}
    >
      <Paper
        elevation={1}
        sx={{
          p: 1.5,
          mb: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderRadius: 2,
          transition: 'background-color 0.2s',
          '&:hover': {
            backgroundColor: 'action.hover',
            cursor: 'pointer'
          },
        }}
        onClick={() => onRowClick(invoice)}
        key={rowKey}
      >
        {/* Date + Vendor */}
        <Stack direction="row" spacing={2} alignItems="center" sx={{ flex: 1 }}>
          <Box sx={{ minWidth: 100 }}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
            >
              <CalendarToday fontSize="inherit" sx={{ opacity: 0.7 }} />
              {dateLabel}
            </Typography>
          </Box>

          <Stack sx={{ flex: 1 }}>
            <Typography variant="subtitle2" noWrap fontWeight="medium">
              {invoice?.vendorName || 'Unknown Vendor'}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              #{invoice?.invoiceNumber || '???'}
            </Typography>
          </Stack>
        </Stack>

        {/* Status + Amount + Arrow */}
        <Stack direction="row" spacing={2} alignItems="center">
          <Box>
            <StatusChip status={invoice?.invoiceStatus} />
          </Box>

          <Box sx={{ minWidth: 70, textAlign: 'right' }}>
            <Typography
              variant="subtitle2"
              sx={{
                color: 'success.main',
                fontWeight: 600
              }}
            >
              ${parseFloat(invoice?.invoiceTotal || 0).toFixed(2)}
            </Typography>
          </Box>

          <Tooltip title="View Details">
            <IconButton
              size="small"
              sx={{
                color: 'text.secondary',
                '&:hover': { color: 'primary.main' }
              }}
            >
              <ArrowForward fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>
      </Paper>
    </Grow>
  );
});

/** =========================================
 * InvoiceList
 * 
 * - TableHeader for sorting
 * - Rows for each invoice
 * - Fades in container, each row grows in
 * ========================================== */
const InvoiceList = ({
  invoices = [],
  onRowClick,
  onSort,
  sortConfig = { field: 'invoiceDate', direction: 'desc' }
}) => {
  return (
    <Fade in timeout={500}>
      <Box
        sx={{
          maxWidth: '100%',
          px: 2,
          py: 1,
          height: '100%',
          overflow: 'auto',
          backgroundColor: 'background.default',
          borderRadius: 2,
        }}
      >
        <TableHeader onSort={onSort} sortConfig={sortConfig} />

        <Stack spacing={1} sx={{ mt: 1 }}>
          {invoices.length > 0 ? (
            invoices.map((inv, idx) => (
              <InvoiceRow
                key={inv?.doc_id || `row-${idx}`}
                invoice={inv}
                index={idx}
                onRowClick={onRowClick}
              />
            ))
          ) : (
            <Paper
              elevation={0}
              sx={{
                p: 4,
                textAlign: 'center',
                backgroundColor: 'background.paper',
                borderRadius: 2,
              }}
            >
              <Typography variant="h6" color="text.secondary">
                No invoices found
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Try adjusting your search or filter criteria
              </Typography>
            </Paper>
          )}
        </Stack>
      </Box>
    </Fade>
  );
};

// Give each component a display name for easier debugging
TableHeader.displayName = 'TableHeader';
StatusChip.displayName = 'StatusChip';
InvoiceRow.displayName = 'InvoiceRow';
InvoiceList.displayName = 'InvoiceList';

export default memo(InvoiceList);
