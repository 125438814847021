import React from "react";
import { useParams, useNavigate, Outlet } from "react-router-dom";
import { Grid, Paper, Box, useTheme, useMediaQuery, Skeleton, Typography } from "@mui/material";
import InvoiceDisplay from "./InvoiceDisplay";
import ProductsList from "./InvoiceList/ProductsList";
import { useSelector } from "react-redux";
import { selectAllInvoices, selectInvoicesLoading } from "../../redux/invoiceSlice";
import { useAuth } from "../../context/AuthContext";

const InvoiceDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { currentUser } = useAuth();

  // Get invoice data from Redux store
  const invoices = useSelector(selectAllInvoices);
  const loading = useSelector(selectInvoicesLoading);
  const invoiceData = invoices?.find((invoice) => invoice.doc_id === id);

  // Handlers
  const handleBackToMain = () => {
    navigate('/invoices');
  };

  const handleInvoiceDeleted = () => {
    navigate('/dashboard/invoices');
  };

  return (
    <Box sx={{ height: "auto", display: "flex", overflow: "hidden" }}>
      <Grid container spacing={2} sx={{ height: "100%", overflow: "hidden", p: 2 }}>
        <Grid item xs={12} md={6} sx={{ height: "100%" }}>
          <Paper elevation={3} sx={{ height: "85vh", overflow: "auto", p: 2 }}>
            {loading ? (
              // Skeleton for loading InvoiceDisplay
              <Skeleton variant="rectangular" width="100%" height="100%" />
            ) : invoiceData ? (
              <InvoiceDisplay invoiceData={invoiceData} />
            ) : (
              <Typography variant="h6" sx={{ textAlign: 'center', mt: 2 }}>
                Invoice not found
              </Typography>
            )}
          </Paper>
        </Grid>

        <Grid item xs={12} md={6} sx={{ height: "100%" }}>
          <Paper elevation={3} sx={{ height: "85vh", overflow: "hidden", p: 2 }}>
            {loading ? (
              // Skeleton for loading ProductsList
              <>
                <Skeleton variant="text" width="80%" height={30} />
                <Skeleton variant="rectangular" width="100%" height="60%" sx={{ mt: 2 }} />
              </>
            ) : invoiceData ? (
              <ProductsList
                invoiceData={invoiceData}
                currentUser={currentUser}
                docId={id}
                onNavigateToMain={handleBackToMain}
                onInvoiceDeleted={handleInvoiceDeleted}
              />
            ) : (
              <Typography variant="h6" sx={{ textAlign: 'center', mt: 2 }}>
                No products available
              </Typography>
            )}
          </Paper>
        </Grid>
      </Grid>

      {/* Outlet for nested routing */}
      <Outlet />
    </Box>
  );
};

export default InvoiceDetail;
