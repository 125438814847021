// src/components/Invoices/inventoryApi.js
import axios from 'axios';

const createInventoryApi = (token) => {
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      authorization: `${token}`,
    },
  });

  return {
    // ----------------------
    // Vendor Endpoints
    // ----------------------
    getAllVendors: () => api.get('/vendors'),
    getVendorById: (vendorId) => api.get(`/vendors/${vendorId}`),
    createVendor: (vendorData) => api.post('/vendors', vendorData),
    updateVendor: (vendorId, updatedFields) => api.patch(`/vendors/${vendorId}`, updatedFields),
    deleteVendor: (vendorId) => api.delete(`/vendors/${vendorId}`),

    // ----------------------
    // Location Endpoints
    // ----------------------
    createLocation: (locationData) => api.post('/locations', locationData),
    getAllLocations: () => api.get('/locations'),
    getLocationById: (locationId) => api.get(`/locations/${locationId}`),
    updateLocation: (locationId, updatedFields) =>
      api.patch(`/locations/${locationId}`, updatedFields),
    deleteLocation: (locationId) => api.delete(`/locations/${locationId}`),

    // ----------------------
    // Product Variation (Items) Endpoints
    // ----------------------
    getAllItems: (filters = {}) => api.get('/items', { params: filters }),
    getItemById: (itemId) => api.get(`/items/${itemId}`),
    createItem: (itemData) => api.post('/items', itemData),
    updateItem: (itemId, updatedFields) =>
      api.patch(`/items/${itemId}`, updatedFields),

    // ----------------------
    // Products (Top-Level)
    // ----------------------
    getAllProducts: (filters = {}) => api.get('/items/products', { params: filters }),
    getProductById: (productId) => api.get(`/items/products/${productId}`),
    createProduct: (productData) => api.post('/items/products', productData),
    updateProduct: (productId, updatedFields) =>
      api.patch(`/items/products/${productId}`, updatedFields),

    // ----------------------
    // Inventory Endpoints
    // ----------------------
    getAllInventory: () => api.get('/inventory'),
    getInventoryById: (inventoryId) => api.get(`/inventory/${inventoryId}`),
    createInventory: (inventoryData) => api.post('/inventory', inventoryData),
    updateInventory: (inventoryId, updatedFields) =>
      api.patch(`/inventory/${inventoryId}`, updatedFields),

    // ----------------------
    // Invoices Endpoints
    // ----------------------
    getAllInvoices: (filters = {}) => api.get('/invoices', { params: filters }),
    getInvoiceById: (invoiceId) => api.get(`/invoices/${invoiceId}`),
    createInvoice: (invoiceData) => api.post('/invoices', invoiceData),
    updateInvoice: (invoiceId, updatedFields) =>
      api.patch(`/invoices/${invoiceId}`, updatedFields),

    // =====================================================
    //    VENDOR-PRODUCTS: Variation-based Endpoints
    // =====================================================
    /**
     * GET /api/items/:variationId/vendor-products
     * Fetch vendor-products for a single variation
     */
    getVendorProductsForVariation: (variationId) =>
      api.get(`/items/${variationId}/vendor-products`),

    /**
     * POST /api/items/:variationId/vendor-products
     * Create a vendor-product link for this variation
     */
    createVendorProductForVariation: (variationId, data) =>
      api.post(`/items/${variationId}/vendor-products`, data),

    /**
     * PATCH /api/items/:variationId/vendor-products/:vendorProductId
     * Update a vendor-product link for this variation
     */
    updateVendorProductForVariation: (variationId, vendorProductId, updatedFields) =>
      api.patch(`/items/${variationId}/vendor-products/${vendorProductId}`, updatedFields),

    /**
     * DELETE /api/items/:variationId/vendor-products/:vendorProductId
     * Remove a vendor-product link for this variation
     */
    deleteVendorProductForVariation: (variationId, vendorProductId) =>
      api.delete(`/items/${variationId}/vendor-products/${vendorProductId}`),

    // =====================================================
    //    VENDOR-PRODUCTS: Vendor-based Endpoints
    // =====================================================
    /**
     * GET /api/vendors/:vendorId/vendor-products
     * Returns vendor-products for a given vendor, with optional filters
     */
    getVendorProductsForVendor: (vendorId, filters = {}) =>
      api.get(`/vendors/${vendorId}/vendor-products`, { params: filters }),

    /**
     * POST /api/vendors/:vendorId/vendor-products
     * Create a vendor-product for a given vendor
     */
    createVendorProduct: (vendorId, data) =>
      api.post(`/vendors/${vendorId}/vendor-products`, data),

    /**
     * PUT (or PATCH) /api/vendors/:vendorId/vendor-products/:vendorProductId
     * Update an existing vendor-product
     */
    updateVendorProduct: (vendorId, vendorProductId, updatedFields) =>
      api.put(`/vendors/${vendorId}/vendor-products/${vendorProductId}`, updatedFields),
    // or if your server uses PATCH:
    // api.patch(`/vendors/${vendorId}/vendor-products/${vendorProductId}`, updatedFields),

    /**
     * DELETE /api/vendors/:vendorId/vendor-products/:vendorProductId
     * Remove a vendor-product
     */
    deleteVendorProduct: (vendorId, vendorProductId) =>
      api.delete(`/vendors/${vendorId}/vendor-products/${vendorProductId}`),
  };
};

export default createInventoryApi;
