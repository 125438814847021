import React, { useState, useEffect, useMemo } from 'react';
import { useAuth } from '../../context/AuthContext';
import createInventoryApi from './inventoryApi'; // Adjust the path
import {
  Container, Typography, IconButton, Box,
  Paper, TextField, InputAdornment, Alert, Button,
  Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Tooltip
} from '@mui/material';
import { Search, Refresh, Edit, Delete } from '@mui/icons-material';

import VendorFormDialog from './vendorFormDialog'; // Our vendor dialog from above

/**
 * Vendors
 * - Fetches the list of vendors from your backend (via inventoryApi).
 * - Renders a table with vendor data, plus Edit/Delete buttons.
 * - Allows search filtering by vendor name.
 * - Provides a 'Create Vendor' button to add a new vendor.
 */
const Vendors = () => {
  const { currentUser } = useAuth();
  
  // Create inventoryApi instance with user’s token
  const inventoryApi = useMemo(
    () => createInventoryApi(currentUser?.stsTokenManager?.accessToken),
    [currentUser]
  );

  // State
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  // Dialog states
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogVendor, setDialogVendor] = useState(null); // vendor object for editing

  /**
   * Fetch vendor list
   */
  const fetchVendors = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await inventoryApi.getAllVendors();
      // shape: { data: { vendors: [...] }, status: 200, ...}
      const vendorArray = response?.data?.vendors || [];
      setVendors(vendorArray);
    } catch (err) {
      console.error('Error fetching vendors:', err);
      setError('Failed to load vendors. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  /**
   * Create or update vendor
   */
  const handleSaveVendor = async (vendorData) => {
    setLoading(true);
    setError(null);
    try {
      if (dialogVendor) {
        // Edit existing vendor
        const vendorId = dialogVendor.id; // unique ID from your vendor
        await inventoryApi.updateVendor(vendorId, vendorData);
        console.log('Vendor updated successfully!');
      } else {
        // Create new vendor
        await inventoryApi.createVendor(vendorData);
        console.log('Vendor created successfully!');
      }
      // Re-fetch after saving
      await fetchVendors();
      setDialogOpen(false);
      setDialogVendor(null);
    } catch (err) {
      console.error('Failed to save vendor:', err);
      setError('Could not save vendor.');
    } finally {
      setLoading(false);
    }
  };

  /**
   * Delete vendor
   */
  const handleDeleteVendor = async (vendor) => {
    if (!window.confirm(`Are you sure you want to delete vendor: ${vendor.name}?`)) {
      return;
    }
    setLoading(true);
    setError(null);
    try {
      await inventoryApi.deleteVendor(vendor.id);
      console.log(`Vendor ${vendor.name} deleted successfully`);
      await fetchVendors();
    } catch (err) {
      console.error('Failed to delete vendor:', err);
      setError('Could not delete vendor.');
    } finally {
      setLoading(false);
    }
  };

  /**
   * Open the dialog to create a new vendor
   */
  const handleCreateClick = () => {
    setDialogVendor(null);
    setDialogOpen(true);
  };

  /**
   * Open the dialog to edit an existing vendor
   */
  const handleEditClick = (vendor) => {
    setDialogVendor(vendor);
    setDialogOpen(true);
  };

  /**
   * Filter by searchTerm
   */
  const filteredVendors = vendors.filter((v) =>
    v.name?.toLowerCase()?.includes(searchTerm.toLowerCase())
  );

  // On mount, fetch vendors
  useEffect(() => {
    fetchVendors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ mb: 4 }}>
        {/* Header + Buttons */}
        <Box
          sx={{
            mb: 3,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h4">Vendors</Typography>
          <Box>
            <IconButton onClick={fetchVendors} disabled={loading} sx={{ mr: 2 }}>
              <Refresh />
            </IconButton>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateClick}
              disabled={loading}
            >
              Create Vendor
            </Button>
          </Box>
        </Box>

        {/* Error message if something goes wrong */}
        {error && (
          <Alert
            severity="error"
            sx={{ mb: 2 }}
            action={
              <IconButton color="inherit" size="small" onClick={fetchVendors}>
                <Refresh />
              </IconButton>
            }
          >
            {error}
          </Alert>
        )}

        {/* Search input */}
        <Box sx={{ mb: 3 }}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Search vendors..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        {/* Vendors table */}
        {loading ? (
          <Typography>Loading vendors...</Typography>
        ) : filteredVendors.length > 0 ? (
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Vendor Name</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Address</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Status</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }} align="right">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredVendors.map((v) => {
                  // If v.address is an object, we safely pick a string to render
                  const addressString = v.address?.addressLine1 
                    || v.address?.locality
                    || v.address?.country
                    || '';

                  return (
                    <TableRow key={v.id}>
                      <TableCell>{v.name}</TableCell>
                      <TableCell>
                        {addressString}
                        {/* If you want to see the entire object, do e.g. JSON.stringify(v.address) */}
                      </TableCell>
                      <TableCell>{v.status}</TableCell>
                      <TableCell align="right">
                        <Tooltip title="Edit Vendor">
                          <IconButton
                            size="small"
                            color="primary"
                            onClick={() => handleEditClick(v)}
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Delete Vendor">
                          <IconButton
                            size="small"
                            color="error"
                            onClick={() => handleDeleteVendor(v)}
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Paper
            sx={{
              p: 4,
              textAlign: 'center',
              bgcolor: 'background.paper',
            }}
          >
            <Typography color="text.secondary" variant="h6">
              No vendors found
            </Typography>
            <Typography color="text.secondary">
              Try adjusting your search terms
            </Typography>
          </Paper>
        )}
      </Box>

      {/* Create/Edit Dialog */}
      <VendorFormDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onSave={handleSaveVendor}
        initialVendor={dialogVendor}
      />
    </Container>
  );
};

export default Vendors;
