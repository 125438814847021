import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button
} from '@mui/material';

/**
 * VendorFormDialog
 * - open (bool) => controls the dialog visibility
 * - onClose() => called when user cancels or finishes
 * - onSave(vendorData) => called with new or updated vendor data
 * - initialVendor (object or null) => vendor object if editing
 */
const VendorFormDialog = ({ open, onClose, onSave, initialVendor }) => {
  const [name, setName] = useState('');
  const [status, setStatus] = useState('ACTIVE');
  const [addressLine1, setAddressLine1] = useState('');
  const [phone, setPhone] = useState('');

  useEffect(() => {
    if (initialVendor) {
      // Populate fields with existing vendor data if editing
      setName(initialVendor.name || '');
      setStatus(initialVendor.status || 'ACTIVE');
      // If vendor.address is an object with addressLine1, phone, etc:
      setAddressLine1(initialVendor.address?.addressLine1 || '');
      setPhone(initialVendor.address?.phone || '');
    } else {
      // Creating new
      setName('');
      setStatus('ACTIVE');
      setAddressLine1('');
      setPhone('');
    }
  }, [initialVendor]);

  const handleSaveClick = () => {
    const vendorData = {
      name,
      status,
      // If your DB schema expects `address` as an object, you can do:
      address: {
        addressLine1,
        phone,
        // Possibly other fields like 'country' if needed
      },
    };
    onSave(vendorData);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {initialVendor ? 'Edit Vendor' : 'Create Vendor'}
      </DialogTitle>
      <DialogContent dividers>
        <TextField
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          margin="normal"
        />

        <TextField
          label="Status"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          fullWidth
          margin="normal"
          placeholder="ACTIVE or INACTIVE"
        />

        <TextField
          label="Address"
          value={addressLine1}
          onChange={(e) => setAddressLine1(e.target.value)}
          fullWidth
          margin="normal"
        />

        <TextField
          label="Phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          fullWidth
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Cancel
        </Button>
        <Button onClick={handleSaveClick} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VendorFormDialog;
