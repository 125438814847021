import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  Avatar,
  CircularProgress,
  Alert,
  Divider,
  Chip,
  Link,
  Stack,
  Zoom,
  Fade,
  Tooltip,
  useTheme
} from '@mui/material';
import { Pending as PendingIcon } from '@mui/icons-material';
import { selectAllInvoices } from '../../redux/invoiceSlice';
import { useAuth } from '../../context/AuthContext';
import moment from 'moment';

const Dashboard = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { currentUser } = useAuth();
  const { displayName = 'Guest', photoURL = '' } = currentUser || {};

  // Redux: invoices + statuses
  const invoices = useSelector(selectAllInvoices);
  const status = useSelector((state) => state.invoices.status);
  const error = useSelector((state) => state.invoices.error);

  // Time
  const [currentTime, setCurrentTime] = useState(moment().format('dddd, MMMM Do YYYY, h:mm:ss A'));

  // Load location JSON from local storage
  const [selectedLocation, setSelectedLocation] = useState(() => {
    const locationJson = localStorage.getItem('preferredLocation');
    if (!locationJson) return null; // no stored location
    try {
      return JSON.parse(locationJson); // parse the object
    } catch (err) {
      console.error('Failed to parse stored location JSON:', err);
      return null;
    }
  });

  // Clock
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().format('dddd, MMMM Do YYYY, h:mm:ss A'));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  // Invoice metrics
  const totalRevenue = invoices.reduce((sum, i) => sum + (i.invoiceTotal || 0), 0);
  const pendingInvoices = invoices.filter((i) => i.invoiceStatus === 'pending').length;
  const newInvoices = invoices.filter((i) => i.invoiceStatus === 'new').length;
  const completedInvoices = invoices.filter((i) => i.invoiceStatus === 'done').length;

  // Latest 5 'new'
  const latestNewInvoices = invoices
    .filter((i) => i.invoiceStatus === 'new')
    .sort((a, b) => new Date(b.invoiceDate) - new Date(a.invoiceDate))
    .slice(0, 5);

  // Helpers
  const formatDate = (d) => moment(d).format('MMMM Do YYYY');
  const getStatusIcon = (st) => (st === 'new' ? <PendingIcon /> : null);
  const getStatusColor = (st) => (st === 'new' ? 'info' : 'default');

  // Loading / Error states
  if (status === 'loading') {
    return (
      <Box sx={{ p: 3, display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return (
      <Alert severity="error" sx={{ m: 3 }}>
        Error loading data: {error}
      </Alert>
    );
  }

  // If we have a stored location object, use its name. Otherwise show fallback text.
  const locationName = selectedLocation?.name || 'No location chosen';

  // Additional fields like address, phone, etc.
  const locationAddress = selectedLocation?.address;
  const locationPhone = selectedLocation?.phone;

  return (
    <Fade in timeout={500}>
      <Box
        sx={{
          minHeight: '100vh',
          bgcolor: 'background.default',
          color: 'text.primary',
          px: { xs: 2, md: 4 },
          py: 4
        }}
      >
        {/* Top Section: Minimal banner */}
        <Box
          sx={{
            mb: 4,
            p: 3,
            borderRadius: 2,
            backgroundColor: 'background.paper',
            boxShadow: theme.shadows[1],
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
              Welcome to Five Spice Dashboard
            </Typography>
            <Stack direction="row" spacing={2} alignItems="center">
              <Avatar src={photoURL} alt={displayName} />
              <Box>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Hello, {displayName}
                </Typography>
                <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                  {currentTime}
                </Typography>
              </Box>
            </Stack>
          </Box>

          {/* Show the user's selected location info */}
          <Zoom in style={{ transitionDelay: '150ms' }}>
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                Currently working in location:
              </Typography>
              <Tooltip title="Your selected location" arrow>
                <Chip
                  label={locationName}
                  color="primary"
                  sx={{
                    fontWeight: 'bold',
                    mt: 1,
                  }}
                />
              </Tooltip>
            </Box>
          </Zoom>
        </Box>

        {/* Latest New Invoices */}
        {latestNewInvoices.length > 0 && (
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
              Latest New Invoices
            </Typography>
            {latestNewInvoices.map((invoice) => (
              <Card
                key={invoice.doc_id}
                variant="outlined"
                sx={{
                  mb: 2,
                  borderRadius: 2,
                  transition: 'transform 0.2s',
                  '&:hover': { transform: 'scale(1.005)' }
                }}
              >
                <CardActionArea
                  onClick={() => navigate(`/dashboard/invoices/${invoice.doc_id}`)}
                  sx={{ p: 2 }}
                >
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={8}>
                      <Typography variant="subtitle1" fontWeight="bold">
                        Invoice #{invoice.doc_id} - {invoice.vendorName}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
                        Date: {formatDate(invoice.invoiceDate)}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Total: ${invoice.invoiceTotal || 'N/A'}
                      </Typography>
                      {invoice.customerName && (
                        <Typography variant="body2" color="text.secondary">
                          Customer: {invoice.customerName}
                        </Typography>
                      )}
                      {invoice.documentURL && (
                        <Link
                          href={invoice.documentURL}
                          target="_blank"
                          rel="noopener noreferrer"
                          sx={{ display: 'inline-block', mt: 0.5 }}
                        >
                          <Typography variant="body2" color="primary">
                            View PDF
                          </Typography>
                        </Link>
                      )}
                    </Grid>
                    <Grid item xs={4} textAlign="right">
                      <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center">
                        <Chip
                          icon={getStatusIcon(invoice.invoiceStatus)}
                          label={invoice.invoiceStatus?.toUpperCase()}
                          color={getStatusColor(invoice.invoiceStatus)}
                          size="small"
                          sx={{ fontWeight: 'bold' }}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                  <Divider sx={{ my: 1.5 }} />
                  <Box>
                    <Typography variant="body2">
                      <strong>Total Items:</strong> {invoice.items.length}
                    </Typography>
                  </Box>
                </CardActionArea>
              </Card>
            ))}
          </Box>
        )}

        {/* Metrics Section */}
        <Grid container spacing={3}>
          {[
            { title: 'New Invoices', value: newInvoices, color: 'info' },
            { title: 'Pending Invoices', value: pendingInvoices, color: 'warning' },
            { title: 'Completed Invoices', value: completedInvoices, color: 'success' },
          ].map(({ title, value, color }, index) => (
            <Grid key={index} item xs={12} sm={6} md={3}>
              <Card
                variant="outlined"
                sx={{
                  p: 3,
                  borderRadius: 2,
                  textAlign: 'center',
                  transition: 'transform 0.2s',
                  '&:hover': { transform: 'scale(1.02)' },
                }}
              >
                <CardContent>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    {title}
                  </Typography>
                  <Typography variant="h4" sx={{ color: `${color}.main`, mt: 1, fontWeight: 'bold' }}>
                    {value}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Fade>
  );
};

export default Dashboard;
